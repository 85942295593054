/* Basic page setup */
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background: #e6e8f8;
}

.profile-page {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 20px;
  background: #e6e8f8;
  height: calc(100vh - 120px);
}

/* Header ("/Profile" + user icon) */
.profile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.profile-header h1 {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0;
}

.user-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #eaeaea;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Main form container */
.profile-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile-form-row {
  display: flex;
  flex-direction: column;
}

/* Labels */
.profile-form-row label {
  font-weight: 500;
  margin-bottom: 6px;
  font-size: 1rem;
  color: #333;
}

/* Inputs & Textareas */
.profile-form-row input,
.profile-form-row textarea {
  font-size: 1rem;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  color: #555;
  background: #f8f9fe;
  outline: none;
  resize: none; /* for textarea */
}

.profile-form-row input:focus,
.profile-form-row textarea:focus {
  border-color: #a3a3ff;
}

/* Reset Password button (where label is "Reset Password") */
.profile-form-row button {
  align-self: flex-start;
  padding: 10px 16px;
  border: none;
  border-radius: 6px;
  background-color: #6e44ff;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
}
.profile-form-row button:hover {
  background-color: #542fbf;
}

/* STATUS MESSAGE area with fade-in/out */
.status-msg-wrapper {
  transition: opacity 3s ease-in-out, max-height 3s ease-in-out;
  overflow: hidden;
}
.status-msg-wrapper.hide {
  opacity: 0;
  max-height: 0;
}
.status-msg-wrapper.show {
  opacity: 1;
  max-height: 100px;
}

/* The status message (success or cancel) */
.status-msg {
  font-weight: 500;
  margin-bottom: 6px;
  padding: 10px;
  border-radius: 6px;
  text-align: center;
}

.status-msg.success {
  background-color: #d7f8d7; /* light green */
  color: #228b22; /* dark green text */
}

.status-msg.cancel {
  background-color: #ffdddd; /* light pink/red */
  color: #c00; /* darker red text */
}

/* Save & Cancel buttons fade in/out */
.buttons-row-wrapper {
  transition: opacity 1s ease, max-height 1s ease;
  overflow: hidden; 
}
.buttons-row-wrapper.hide {
  opacity: 0;
  max-height: 0;
}
.buttons-row-wrapper.show {
  opacity: 1;
  max-height: 80px; 
}

/* The row containing Save & Cancel */
.buttons-row {
  flex-direction: row;
  gap: 10px;
}

/* Save & Cancel Buttons */
.save-btn,
.cancel-btn {
  padding: 10px 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
}

.save-btn {
  background-color: #6e44ff;
  color: #fff;
}
.save-btn:hover {
  background-color: #542fbf;
}

.cancel-btn {
  background-color: #cc0000;
  color: #fff;
}
.cancel-btn:hover {
  background-color: #990000;
}

/* Skills Wizard background overlay */
.skills-wizard-bg {
  margin-top: 30px;
  padding: 30px 0;
  background: linear-gradient(135deg, #6e44ff, #e6e8f8);
  animation: gradientAnimation 12s ease infinite;
  background-size: 400% 400%;
  border-radius: 12px;
}

/* Gradient animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Wizard container */
.skills-wizard {
  max-width: 800px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  animation: fadeIn 0.5s forwards;
}

/* Fade-in animation for wizard steps */
.fade-in {
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Monday.com-like category blocks */
.monday-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.monday-block {
  background: #f1f2f6;
  border: 2px solid #d0d3e0;
  border-radius: 8px;
  padding: 12px 20px;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 140px;
  text-align: center;
  font-weight: 500;
  color: #555;
}

.monday-block.selected {
  background: #6e44ff;
  color: #fff;
  border-color: #6e44ff;
}

.monday-block:hover {
  background: #d0d3e0;
}

/* Subdomain blocks */
.subdomain-block {
  margin: 20px 0;
  background: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
}

.monday-subblock {
  background: #f9f9f9;
  border: 2px solid #eee;
  padding: 8px 14px;
  border-radius: 6px;
  cursor: pointer;
  margin: 6px 0;
  transition: all 0.2s;
  color: #444;
}

.monday-subblock.selected {
  background: #6e44ff;
  color: #fff;
  border-color: #6e44ff;
}

.monday-subblock:hover {
  background: #eaeaea;
}

/* Sub-subdomain blocks */
.subsub-block {
  margin-left: 20px;
  margin-top: 8px;
}

.monday-subsubblock {
  background: #fff;
  border: 2px solid #eee;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin: 4px 0;
  transition: background 0.2s;
  color: #666;
}

.monday-subsubblock.selected {
  background: #6e44ff;
  color: #fff;
  border-color: #6e44ff;
}

.monday-subsubblock:hover {
  background: #eee;
}

/* Star rating */
.star-rating {
  font-size: 1.5rem;
  cursor: pointer;
  display: inline-block;
}

.profilebutton {
  background-color: black !important;
  color: white !important;
  border-radius: 25px;
  padding: 6px 20px;
  font-family: 'Poppins', sans-serif;
  width:fit-content;
  cursor: pointer;
  font-size: 1rem;
  border: none;
  transition: background-color 0.3s;
}

.star {
  color: #ccc;
  margin-right: 4px;
  transition: color 0.2s;
}

.star.filled {
  color: #ffcc00;
}

.star:hover {
  color: #ff9900;
}

/* Proficiency/Experience/Interest row */
.rating-row {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 5px;
}

/* Monday-style dropdowns */
.monday-dropdown {
  border: 2px solid #ddd;
  border-radius: 6px;
  padding: 6px;
  font-size: 1rem;
  outline: none;
  color: #333;
  background-color: #fff;
  cursor: pointer;
}

.monday-dropdown:focus {
  border-color: #6e44ff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .profile-header {
    flex-direction: column;
    gap: 20px;
  }

  .profile-form-row {
    gap: 6px;
  }
}
