.build-profile-container {
    width: 90%;
    max-width: 1200px;
    margin: 40px auto;
    color: #333;
  }
  
  .step-navigation {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  .step-item {
    margin-right: 40px;
  }
  
  .section-container {
    margin-top: 20px;
  }
  .category-row {
    margin-bottom: 25px;
  }
  .detail-boxes {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .detail-cell {
    background-color: #f7f5ff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    min-width: 180px;
    position: relative;
  }
  .detail-cell.added {
    border: 1px solid #7b48ff;
  }
  .placeholder {
    opacity: 0.7;
  }
  
  .detail-text {
    flex: 1;
    font-size: 14px;
  }
  
  .add-button {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #7b48ff;
    color: #fff;
    border: none;
    cursor: pointer;
    margin-left: 8px;
  }
  
  .footer-actions {
    margin-top: 40px;
    display: flex;
    gap: 20px;
  }
  