/**********************
   Overall Container
**********************/
.business-chat-container313 {
  position: relative; 
  width: 100%;
  height: calc(100vh - 130px);
  display: flex;
  flex-direction: column;
  background-color: #e6e8f8; 
}

/**********************
   Header pinned at top
**********************/
.business-chat-header313 {
  font-size: 1.8rem;
  font-weight: 700;
  text-align: left;
  color: #000;
  margin: 0;
  padding: 10px 0;
  background-color: #e6e8f8;
  position: sticky;
  top: 0;
  z-index: 10; 
}

.chat-header-x313 {
  color: #6e44ff;
}

/* If there's an image */
.chat-image-attachment {
  max-width: 200px;
  max-height: 300px;
  border-radius: 6px;
  margin-top: 8px;
  display: block;
  object-fit: cover;
}

/**********************
  Scrollable messages area
**********************/
.business-messages-wrapper313 {
  flex: 1; 
  overflow-y: auto;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/**********************
   Chat Input Bar (outer)
**********************/
.business-chat-input-bar313 {
  position: sticky; 
  bottom: 0; 
  left: 0; 
  right: 0;
  z-index: 999;
  display: flex;       /* center our inner content horizontally */
  justify-content: center;
  background-color: transparent;
  height: 50px;
  margin-bottom: 10px;
}

/**********************
   Inner wrapper => max-width=1000px
**********************/
.input-bar-inner313 {
  max-width: 1300px; 
  width: 100%;       /* let it shrink if the screen is narrower */
  margin: 0 auto;    /* center horizontally inside the sticky bar */
  padding: 20px 15px; 
  display: flex;
  align-items: center;
  background-color: #fff;
  gap: 10px;
  border-radius: 30px;
}

/* Attach & Send icons */
.attach-btn313,
.send-btn313 {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}
.attach-btn313 img,
.send-btn313 img {
  width: 24px;
  height: 24px;
}

/* The text input */
.input-bar-inner313 input[type="text"] {
  flex: 1; 
  border: none;
  outline: none;
  font-size: 1rem;
  margin-top: 10px;
  background-color: transparent; 
  border-radius: 4px;
}

/**********************
   The entire message row
**********************/
.chat-item313.message-row313 {
  display: flex;
  align-items: flex-start; 
  gap: 10px;
}

/* If sender => row-reverse (avatar on right) */
.sender313 {
  margin-left: auto;
}
.chat-item313.message-row313.sender313 {
  flex-direction: row-reverse;
}

.recipient313 {
  margin-right: auto;
}

/**********************
   Avatar
**********************/
.chat-icon313 {
  width: 40px; 
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

/**********************
   Message bubble + date
**********************/
.chat-message-wrapper313 {
  display: flex;
  flex-direction: column;
}

.chat-bubble313 {
  background-color: #fff;
  cursor: pointer;
  border-radius: 10px;
  padding: 12px;
  max-width: 1000px; 
  box-shadow: 0 0 5px rgba(0,0,0,0.05);
}

/* Username inside the bubble */
.chat-user313 {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 4px;
}

/* The actual message text */
.chat-message-text313 {
  font-size: 1rem;
  color: #333;
  margin: 0;
  line-height: 1.4;
}

/**********************
   The date/time below the bubble
**********************/
.chat-date313 {
  font-size: 0.85rem;
  color: #555;
  margin-top: 6px;
}

/* The quoted snippet inside the bubble */
.quoted-message313 {
  background-color: #f3f3f3;
  border-left: 3px solid #999;
  padding: 4px 8px;
  margin-bottom: 4px;
  font-size: 0.9rem;
  border-radius: 20px;
}

/* The meta row that holds time + "Reply" button */
.chat-meta-row313 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
}

/* The "Reply" button under the bubble */
.reply-btn313 {
  background: none;
  border: none;
  padding: 10px;
  color: #777;
  font-size: 0.85rem;
  cursor: pointer;
}
.reply-btn313:hover {
  color: #333;
  text-decoration: underline;
}

/* The bar shown above the input when replying */
.replying-to-bar313 {
  background-color: #fff6e0;
  border-top: 1px solid #ccc;
  padding: 8px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.replying-to-text313 {
  font-size: 0.9rem;
}
.cancel-reply-btn313 {
  background: none;
  border: none;
  font-size: 1.2rem;
  line-height: 1;
  color: #666;
  cursor: pointer;
}
.cancel-reply-btn313:hover {
  color: #333;
}

/**********************
   Drag & drop overlay
**********************/
.business-chat-container313.drag-over {
  position: relative; 
}
.drag-overlay {
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%; 
  height: 100%;
  background: rgba(230, 232, 248, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.drag-overlay-content {
  text-align: center;
}
.drag-plus-sign {
  font-size: 4rem;
  color: #6e44ff;
  margin-bottom: 0.5rem;
}

/***********************************
   Preview container in input bar
************************************/
.preview-container313 {
  display: flex;
  align-items: center;
  gap: 8px;
}
.preview-image313 {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.preview-buttons313 button {
  background-color: #6e44ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  margin-right: 5px;
}
.preview-buttons313 button:hover {
  opacity: 0.9;
}
.preview-buttons313 button:last-child {
  background-color: #999;
}

/***********************************
   Fullscreen overlay for expanded image
************************************/
.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  cursor: zoom-out; 
}
.image-modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  margin: auto;
  background-color: transparent;
  border-radius: 4px;
  cursor: auto; 
}
.image-modal-img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
  transition: transform 0.2s ease;
}
.image-modal-close {
  position: absolute;
  top: 8px;
  right: 12px;
  font-size: 2rem;
  line-height: 1;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}
.image-modal-close:hover {
  color: #ddd;
}

/**********************
   Mobile or smaller screens
**********************/
@media (max-width: 900px) {
  .business-chat-header313 {
    font-size: 1.4rem;
  }
  .input-bar-inner313 {
    flex-wrap: wrap;
    gap: 8px;
  }
  .chat-icon313 {
    width: 35px;
    height: 35px;
  }
  .business-messages-wrapper313 {
    padding: 0px;
  }
}
