.upload-cv-container {
  min-height: 100vh;
  background: linear-gradient(135deg, #1d3557, #457b9d);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;
}

.upload-cv-content {
  background-color: rgba(255, 255, 255, 0.92);
  border-radius: 16px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  max-width: 500px;
  width: 100%;
  padding: 2rem;
  text-align: center;
}

/* Title */
.upload-cv-content h1 {
  margin-bottom: 1rem;
  color: #1d3557;
}

/* Progress display */
.upload-progress {
  margin-top: 1rem;
}

.upload-progress p {
  margin: 0 0 0.5rem;
  font-weight: bold;
}

/* A simple progress bar container */
.progress-bar {
  width: 100%;
  background-color: #eee;
  border-radius: 8px;
  overflow: hidden;
  height: 10px;
}

/* The filled portion of the progress bar */
.progress-bar-fill {
  background-color: #457b9d;
  height: 100%;
  width: 0%;
  transition: width 0.3s ease;
}

/* Success message */
.upload-success-message {
  margin-top: 1rem;
  color: #06d6a0;
  font-weight: 500;
  text-align: center;
}

.upload-success-message a {
  display: inline-block;
  margin-top: 0.5rem;
  color: #2a9d8f;
  text-decoration: none;
  font-weight: bold;
}

.upload-success-message a:hover {
  text-decoration: underline;
}
