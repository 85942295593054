/***************************
 * Container & General
 ***************************/
 .project-board-container {
  height: calc(100vh - 120px);
  padding: 2rem 4rem;
  background-color: #e6e8f8; /* Light grey/purple background */
  opacity: 0; /* For fade in effect */
  transition: opacity 0.6s ease;
}

.project-board-container.loaded {
  opacity: 1;
}

/***************************
 * Heading
 ***************************/
.project-board-heading {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #2e2e2e;
  font-family: 'Poppins', sans-serif;
}

/***************************
 * Grid Layout
 ***************************/
.project-board-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 2rem;
}

/* 
  *** WHEN SIDEBAR IS OPEN => 2 columns ***
  The "!important" helps ensure it overrides the default auto-fill columns 
*/
.project-board-container.sidebar-open .project-board-grid {
  grid-template-columns: repeat(2, 1fr) !important;
}

/***************************
 * Project Card
 ***************************/
.project-card1 {
  background-color: transparent !important;
  border-radius: 8px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Slash Heading => Project Title */
.slash-heading {
  font-family: 'Poppins', sans-serif;
  font-size: 1.4rem !important;
  font-weight: bold;
  margin-bottom: 1rem;
}

/***************************
 * Business Box
 ***************************/
.business-box {
  display: flex;
  align-items: center;
  background-color: #f3f3f8;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin: 1rem 0rem;
  position: relative;
}

.business-logo-container {
  margin-right: 1rem;
}

.business-initial {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: transparent;
  color: #fff;
  font-size: 1.8rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.business-info {
  flex: 1;
}

.business-name {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  color: #000;
}

.business-budget {
  position: absolute;
  right: 1rem;
  font-weight: 600;
  color: #333;
}

/***************************
 * Match Score
 ***************************/
.match-score {
  font-size: 0.9rem;
  color: #8c8c8c;
  margin-bottom: 1rem;
  text-transform: lowercase;
}

/***************************
 * Apply Button
 ***************************/
.apply-button {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: #000;
  padding: 0.6rem 1.2rem;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  width: 100px;
  transition: background-color 0.2s;
}

.apply-button:hover {
  background-color: #333;
}

.apply-button.applied {
  background-color: #aaa;
  cursor: default;
}

/***************************
 * Responsive
 ***************************/
@media (max-width: 768px) {
  .business-budget {
    position: static;
    margin-top: 0.5rem;
  }

  .business-box {
    flex-direction: column;
    align-items: flex-start;
  }

  .business-info {
    margin-bottom: 0.5rem;
  }

  .project-board-heading {
    font-size: 2.2rem;
  }
}
