/* Booking.css */

/* Main container */
.booking-container {
  display: flex;
  height: calc(100vh - 200px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 900px;
  margin: 50px auto;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 15px;
}

/* Header styling */
.booking-header {
  text-align: center;
  margin-bottom: 0px;
}

.booking-logo {
  font-size: 3rem;
  font-weight: bold;
  color: #333;
}

.booking-logo span {
  color: #5e17eb;
}

/* Booking form */
.booking-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
}

/* Input fields */
.input-container123 {
  margin-bottom: 20px;
}

.input-box123 {
  width: 100% !important;
  height: 30px !important;
  padding: 10px !important;
  font-size: 1.2rem !important;
  color: #333 !important;
  border: none !important;
  border-radius: 10px !important;
  background-color: #f6f7ff !important;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1) !important;
  transition: border 0.3s ease, box-shadow 0.3s ease !important;
}

.input-box1234 {
  width: 100% !important;
  height: 50px !important;
  padding: 10px !important;
  font-size: 1.2rem !important;
  color: #333 !important;
  border: none !important;
  border-radius: 10px !important;
  background-color: #f6f7ff !important;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1) !important;
  transition: border 0.3s ease, box-shadow 0.3s ease !important;
}
.input-box123:focus {
  outline: none;
  border: 2px solid #5e17eb;
  box-shadow: 0 0 8px rgba(94, 23, 235, 0.3);
}

textarea.input-box123 {
  resize: none;
}

/* Calendar container */
.calendar-container {
  margin: 20px 0;
}

.react-calendar {
  border: none;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
}

.react-calendar__tile {
  text-align: center;
  color: #333 !important; /* Set all text to black */
  border-radius: 10px;
  transition: all 0.3s ease;
}

.react-calendar__tile--active {
  background: #5e17eb !important;
  color: white !important;
}

.react-calendar__tile--now {
  background: #f0f0f0 !important;
  color: #333 !important;
  border: 1px solid #5e17eb;
}

.react-calendar__tile--weekend {
  color: #333 !important; /* Override default red for weekends */
}

.react-calendar__navigation button {
  color: #5e17eb;
  font-weight: bold;
}
/* Time dropdown */
select.input-box123 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='%235e17eb' d='M10 12l5-5H5l5 5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 15px;
}

/* Button styling */
.link-button123 {
  background-color: black;
  color: white;
  border: none;
  font-weight: bold;
  padding: 10px 30px;
  border-radius: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.link-button123:hover {
  background-color: #4d4d4d;
  transform: translateY(-2px);
}

.link-button123:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Response message */
.response-message {
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: #5e17eb;
}


@media (max-width: 1000px) {
  .booking-container{
    height: calc(100vh);
  }

  .input-box123 {
    width: 90vw !important;
    height: 30px !important;
    padding: 10px !important;
    font-size: 1.2rem !important;
    color: #333 !important;
    border: none !important;
    border-radius: 10px !important;
    background-color: #f6f7ff !important;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1) !important;
    transition: border 0.3s ease, box-shadow 0.3s ease !important;
  }
}
