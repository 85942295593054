.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: auto;
  padding: 40px;
  font-family: 'Poppins' !important;
}

.signup-container h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

/* Unified input styling for all input types: text, email, password */
input[type='text'],
input[type='email'],
input[type='password'] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: none;
  background-color: #f6f7ff;
  border-radius: 8px;
  font-size: 1rem;
  height: 40px;
  font-family: 'Poppins', Arial, sans-serif;
}

/* Terms checkbox */
.terms-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.terms-checkbox label {
  font-size: 0.9rem;
  white-space: nowrap;
  font-family: 'Poppins', Arial, sans-serif;
}

.terms-link {
  color: #6e44ff;
  text-decoration: underline;
  cursor: pointer;
}

.terms-link:hover {
  text-decoration: none;
}

/* Continue button */
button.continue-button {
  width: 100%;
  height: 50px;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 100;
  margin-bottom: 15px;
  font-family: 'Poppins', Arial, sans-serif;
}

button.continue-button:hover {
  background-color: #333;
}

/* Divider line */
.divider {
  width: 100%;
  margin: 20px 0;
  border: 0.5px solid #ccc;
}

/* Error and Success messages */
.error-text {
  color: red;
  margin-top: 5px;
  font-family: 'Poppins', Arial, sans-serif;
}

.success-text {
  color: green;
  margin-top: 10px;
  font-family: 'Poppins', Arial, sans-serif;
}

/* Social buttons */
.social-button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 1rem;
  border: 1px solid #ccc;
  cursor: pointer;
  align-items: left;
  text-align: left;
  background-color: #f6f7ff;
  color: black;
  font-family: 'Poppins', Arial, sans-serif;
}

.social-button:hover {
  background-color: #b0b1b7;
}

.social-logo {
  width: 20px;
  height: auto;
  margin-right: 20px;
  margin-bottom: -3px;
  margin-left: 10px;
}

.apple-button, .google-button {
  width: 100%;
  height: 50px;
  padding: 10px;
  background-color: #f6f7ff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  font-family: 'Poppins', Arial, sans-serif;
  margin-bottom: 15px;
}

/* Terms popup overlay */
.terms-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.terms-popup-content {
  background-color: white;
  width: 80%;
  max-width: 800px;
  max-height: 80%;
  overflow-y: auto;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  font-family: 'Poppins', Arial, sans-serif;
}

.terms-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.terms-header h2 {
  margin-top: 0;
}

.close-popup {
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.terms-content {
  max-height: 60vh;
  overflow-y: scroll;
  margin-top: 20px;
  text-align: left;
  font-family: 'Poppins', Arial, sans-serif;
}

.terms-content h2,
.terms-content h3 {
  margin-top: 20px;
}

.terms-content ul {
  list-style-type: disc;
  padding-left: 20px;
}

.terms-content li {
  margin-bottom: 10px;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .signup-container {
    padding: 20px;
  }

  input[type='text'],
  input[type='email'],
  input[type='password'] {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .continue-button {
    font-size: 1rem;
  }

  .social-button,
  .apple-button,
  .google-button {
    font-size: 1rem;
  }

  .terms-popup-content {
    width: 90%;
    max-width: none;
  }
}
