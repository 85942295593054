/* =========== GLOBAL =========== */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #e6e8f8; 
  color: #000;
}

/* 
  The main container remains the same:
  - position: relative for absolutely-positioned background text
  - 100vh height
  - max-width constraint
*/
.project-optimization-container53 {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100vh;
  max-width: 1000px;
  margin: 0 auto;
  padding: 10px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

/* 
  ONE background text block that:
  - fills entire container (top: 0; left: 0; right: 0; bottom: 0;)
  - uses flex to center text horizontally + vertically
  - takes up as much width as possible
*/
.background-text53 {
  position: absolute;
  top: 0;
  left: 10px;
  right: 10px;
  bottom: 0;

  display: flex;            /* allow horizontal/vertical centering */
  align-items: center;      /* vertical center */
  justify-content: center;  /* horizontal center */

  pointer-events: none;     /* clicks pass through to content */
  z-index: 0;               /* behind heading & input bubble */

  /* The text styling */
  font-size: 5rem;
  font-weight: 700;
  color: #ccc;
  opacity: 0.55;
  line-height: 1.2;
  text-align: center;
  white-space: pre-wrap;
  overflow: hidden;
}

/* Heading above input bubble */
.heading-wrapper53 {
  margin-top: 400px;
  margin-bottom: 20px;
  text-align: left; 
  position: relative;
  z-index: 2; /* above background text */
}

.moccet-ai-title53 {
  font-size: 1.4rem;
  font-weight: 600;
  color: #333;
  margin: 0;
  text-align: left;
}

/* =========== INPUT BUBBLE =========== */
.input-bubble53 {
  position: relative;
  z-index: 2; 
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  background-color: #f6f6ff;
  border-radius: 22px;
  padding: 0 16px;
  box-sizing: border-box;
  overflow: hidden;
}

.single-line-input53 {
  appearance: none;
  border: none;
  background: transparent;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  color: #333;
  height: 48px;
  line-height: 48px;
  flex: 1;
  outline: none;
  margin: 0;
  cursor: text;
  margin-top: 13px; /* if you need the text to sit lower within input bubble */
}

/* Placeholder wrapper + animation */
.placeholder-wrapper53 {
  position: absolute;
  top: 0;
  left: 16px;
  right: 60px;
  height: 44px;
  display: flex;
  align-items: center;
  pointer-events: none;
  background-color: #f6f6ff;
  overflow: hidden;
}
.placeholder-text53 {
  font-size: 1rem;
  color: #aaa;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  transition: 0.3s;
}
.placeholder-text53.animate {
  animation: placeholderSlideUp53 0.8s forwards;
}

@keyframes placeholderSlideUp53 {
  0% {
    transform: translateY(1px);
    opacity: 1;
  }
  45% {
    transform: translateY(-99px);
    opacity: 0;
  }
  55% {
    transform: translateY(99px);
    opacity: 0;
  }
  100% {
    transform: translateY(1px);
    opacity: 1;
  }
}

/* The arrow button */
.arrow-button53 {
  width: 32px;
  height: 32px;
  border: none;
  background-color: transparent;
  margin-left: 12px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.arrow123 {
  width: 32px;
  height: 32px;
}
.arrow-button53:hover {
  background-color: transparent !important;
}

/* =========== LOADING SCREEN (unchanged) =========== */
.loading-screen53 {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0 auto;
  max-width: 1200px;
  box-sizing: border-box;
  padding: 40px 20px;
  background-color: #e6e8f8;
}
.loading-inner {
  align-items: flex-start; 
  justify-content: flex-start; 
  margin: 0 auto;
}
.loading-header53 {
  margin-top: 250px;
  margin-bottom: 30px;
  text-align: left;
}
.loading-brief-title53 {
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0 0 8px;
}
.loading-subtitle53 {
  font-size: 1.3rem;
  margin: 0;
  color: #666;
}
.loading-subtitle53 span {
  color: #6b44e7;
  font-weight: 600;
}
.loading-prompt53 {
  font-size: 1.3rem;
  font-weight: 600;
  color: #000;
  max-width: 1400px;
  line-height: 1.4;
  margin: 0 0 30px;
}
.loading-prompt53 span {
  color: #6b44e7; 
}
.dots-container53 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
}
.loading-info53 {
  font-size: 1rem;
  color: #777;
  max-width: 900px;
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 0;
}

/* Dot animation for loading screen */
.dot53 {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  background-color: #5e17eb;
  border-radius: 50%;
  opacity: 0;
  animation: dots53 1.5s infinite ease-in-out;
}
.dot53:last-child {
  margin-right: 0;
}
.dot1 { animation-delay: 0s; }
.dot2 { animation-delay: 0.3s; }
.dot3 { animation-delay: 0.6s; }
.dot4 { animation-delay: 0.9s; }
.dot5 { animation-delay: 1.2s; }

@keyframes dots53 {
  0%, 80%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

/* =========== RESPONSIVE =========== */
@media (max-width: 600px) {
  .project-optimization-container53 {
    height: 100vh;
  }

  .background-text53 {
    font-size: 1.6rem;  /* smaller on mobile */
  }
  .input-bubble53 {
    height: 40px;
    border-radius: 20px;
  }
  .single-line-input53 {
    height: 40px;
    line-height: 40px;
    transform: translateY(0);
  }
  .placeholder-wrapper53 {
    height: 40px;
  }
  .placeholder-text53 {
    line-height: 40px;
    transform: translateY(0);
  }
  .arrow-button53 {
    width: 28px;
    height: 28px;
    margin-left: 8px;
  }
  .arrow123 {
    width: 28px;
    height: 28px;
  }
  
  /* Loading screen smaller text adjustments */
  .loading-screen53 {
    padding: 20px;
  }
  .loading-header53 {
    margin-bottom: 20px;
  }
  .loading-prompt53 {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  .dots-container53 {
    margin-bottom: 20px;
  }
  .loading-info53 {
    font-size: 0.95rem;
  }
}
