/* BusinessProfile.css */

/* Reset body margin */
body {
  margin: 0;
}

.business-profile-container1 {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
}

.content1 {
  flex: 1; /* Allows content to grow and push footer to the bottom */
  padding: 40px;
  background-color: #e6e8f8;
  font-family: 'Poppins';
  display: flex;
  flex-direction: column;
  align-items: left;
}

.business-name1 {
  font-size: 3rem;
  font-weight: bold;
  color: #6e44ff;
  margin-bottom: 20px;
  text-align: left;
}

.account-title1 {
  font-size: 2rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 60px;
  text-align: left;
}

.account-details1 {
  width: 100%;
  max-width: 600px;
}

.detail-row1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.detail-label1 {
  font-weight: bold;
  font-size: 1.4rem;
  color: #000;
}

.detail-value1 {
  font-size: 1.4rem;
  color: #706f6f;
}

.edit-details1 {
  font-size: 1.6rem;
  font-weight: bold;
  cursor: pointer;
  text-align: left;
  padding-top: 40px;
}

.edit-details1:hover {
  color: #4a0db8;
}

footer {
  width: 100%;
}

.detail-input1 {
  font-size: 1.4rem;
  color: white;
  width: 60%;
  padding: 5px;
  margin-left: 20px;
}

.save1 {
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  color: black;
  text-align: left;
}

.change-password1 {
  font-size: 1.4rem;
  color: #6e44ff;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .content1 {
    padding: 20px;
  }

  .business-name1 {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .account-title1 {
    font-size: 1.5rem;
    margin-bottom: 40px;
  }

  .detail-label1,
  .detail-value1 {
    font-size: 1.2rem;
  }

  .detail-row1 {
    flex-direction: column; /* Stack label and value vertically */
    align-items: center;
    text-align: center;
  }

  .detail-label1,
  .detail-value1 {
    width: 100%;
    margin: 5px 0;
  }

  .edit-details1 {
    font-size: 1.4rem;
  }
}
