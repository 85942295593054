/* BusinessSignUp.css */

.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    margin: auto;
    padding: 40px;
    font-family: Arial, Poppins;
  }
  
  h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  
  input[type='email'], input[type='password'] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: none;
    background-color: #f6f7ff;
    border-radius: 8px;
    font-size: 1rem;
    height: 40px;
  }
  
  .terms-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .terms-checkbox label {
    font-size: 0.9rem;
    white-space: nowrap;
  }
  
  button.continue-button {
    width: 100%;
    height: 50px;
    padding: 10px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 100;
    margin-bottom: 15px;
  }
  
  button.continue-button:hover {
    background-color: #333;
  }
  
  .social-button {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 1rem;
    border: 1px solid #ccc;
    cursor: pointer;
    align-items: left;
    text-align: left;
  }

  .social-button:hover{
    background-color: #b0b1b7;
  }

.social-logo {
  width: 20px; /* Adjust size as needed */
  height: auto;
  margin-right: 20px; /* Space between the logo and text */
  margin-bottom: -3px;
  margin-left: 10px;
}
  
  .apple-button, .google-button, .continue-button {
    width: 100%;
    height: 50px;
    padding: 10px;
    background-color: #f6f7ff;
    color: black;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.1rem;
    font-family: 'Poppins' !important;
    margin-bottom: 15px;
  }
  
  .divider {
    width: 100%;
    margin: 20px 0;
    border: 0.5px solid #ccc;
  }
  
  .error-text {
    color: red;
  }
  
  .success-text {
    color: green;
  }
  
  .terms-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .terms-popup-content {
    background-color: white;
    width: 80%;
    max-width: 800px;
    max-height: 80%;
    overflow-y: auto;
    padding: 20px;
    border-radius: 8px;
    position: relative;
  }
  
  .terms-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .close-popup {
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .terms-content {
    max-height: 60vh;
    overflow-y: scroll;
    margin-top: 20px;
    text-align: left;
  }
  
  @media (max-width: 768px) {
    .terms-checkbox {
      font-size: 0.8rem;
    }
  }