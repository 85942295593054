@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* 
  .bar and .navbar have default backgrounds here,
  but they will be overridden by inline styles
  (where we set transparent if scrolled).
*/

/* Sticky container */
.bar {
  width: 100%;
  background-color: #e6e8f8;
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Main navbar row */
.navbar {
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: #e6e8f8;
  padding: 0 20px;
}

/* Logo area */
.logo-area {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.text-logo {
  display: inline-block;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.m-img,
.occet-img,
.dot-img {
  height: 60px;
  width: auto;
}

.occet-img {
  margin-left: -20px;
}

.dot-img {
  margin-left: -23px;
}

/* 
  Scroll animation for the .occet-img, .dot-img
  Just an example: fades out the "occet-img" when scrolled
  and slides the dot-img to the left
*/
.text-logo.scrolled .occet-img {
  opacity: 0;
  transition: opacity 0.4s ease;
}

.text-logo.scrolled .dot-img {
  transition: transform 0.4s ease;
  transform: translateX(-85px);
}

/* Desktop nav links container */
.links-container-desktop {
  display: flex;
  align-items: center;
  gap: 15px;
}

/* Default link style reset */
.links-container-desktop span {
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  font-size: 1.2rem;
  background: none;
  border: none;
  font-weight: 400;
  color: #000;
}

/* Hover effect for regular text links (desktop) */
.links-container-desktop span:hover {
  color: #5e17eb;
}

/* MOBILE MENU ICON */
.mobile-menu-icon {
  display: none;
  font-size: 1.8rem;
  cursor: pointer;
  z-index: 1001;
}

.close-icon {
  font-size: 1.8rem;
  color: #000;
}

.hamburger-icon {
  font-size: 1.8rem;
  color: #000;
}

/* MOBILE MENU OVERLAY */
.links-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f2efeb;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  transform: translateY(-150%);
  transition: transform 0.3s ease-in-out;
  z-index: 999;
}

.links-container.open {
  transform: translateY(0);
}

.links {
  display: none;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.links.mobile-dropdown {
  display: flex;
}

/* Mobile nav link styles */
.links.mobile-dropdown p {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  color: #000;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.2s;
}

.links.mobile-dropdown p:hover {
  color: #5e17eb;
}

/* Hide desktop links & show mobile icon on smaller screens */
@media (max-width: 1100px) {
  .links-container-desktop {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
    margin-left: auto;
  }
}

/* Desktop user icon (for logged-in users) */
.desktop-user-icon {
  display: none;
  cursor: pointer;
  color: #000;
}

/* Show user icon on large screens */
@media (min-width: 1101px) {
  .desktop-user-icon {
    display: block;
  }
}

/* USER SIDEBAR (slide in from the right) */
.user-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 270px;
  height: 100vh;
  background-color: #fff;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
}

.user-sidebar.open {
  transform: translateX(0);
}

.user-sidebar-header {
  display: flex;
  justify-content: flex-end;
}

.close-user-sidebar {
  font-size: 2rem;
  cursor: pointer;
  margin-right: 10px;
}

.user-sidebar-links {
  margin-top: 20px;
}

.user-sidebar-links p {
  margin: 1rem 0;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
}

.user-sidebar-links p:hover {
  color: #5e17eb;
}

/* Footer-like section at bottom of user sidebar */
.user-sidebar-bottom {
  margin-top: auto;
}

.user-sidebar-bottom p {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  color: #555;
  cursor: pointer;
}

.user-sidebar-bottom p:hover {
  color: #5e17eb;
}

.nothing:hover {
  color: #555 !important;
  cursor: default;
}

/* =========== NEW BUTTON STYLES =========== */
/* 1) Solid Black Button (e.g. "Use Moccet AI") */
.navbar-button-black {
  background-color: #000 !important;
  color: #fff !important;
  border: none;
  border-radius: 25px;
  padding: 6px 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}
.navbar-button-black:hover {
  background-color: #333;
}

/* 2) Outline Button (e.g. "Blog", "Log in", "Sign up") */
.navbar-button-outline {
  background-color: transparent;
  color: #000;
  border: 1px solid #000 !important;
  border-radius: 25px;
  padding: 6px 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
.navbar-button-outline:hover {
  background-color: #000;
  color: #fff;
}

/* 3) Outline Icon Button (Envelope) */
.navbar-icon-outline {
  background-color: transparent;
  color: #000;
  border: 1px solid #000 !important;
  border-radius: 50%;
  padding: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
.navbar-icon-outline:hover {
  background-color: #000;
  color: #fff;
}

/* Badge styling for unread notifications */
.collaborate-link.unread {
  position: relative;
  font-weight: 600;
  color: #000;
}

.unread-count {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 0.8rem;
  color: red;
  background-color: transparent;
}

.desktop-unread {
  font-size: 0.8rem;
  top: -5px;
  right: -8px;
}
