.footer {
  /* Lay out the left, center, and right horizontally in desktop view */
  display: flex;
  align-items: center;       /* Vertically center items in desktop */
  justify-content: space-between;
  flex-wrap: wrap;           /* Wrap on smaller screens if needed */
  
  background-color: #000;    /* Dark background */
  color: #fff;
  font-family: 'Poppins', sans-serif;
  padding: 40px;
  box-sizing: border-box;
  width: 100%;
}

/* ----- Left (Logo) ----- */
.footer-left {
  /* Minimal styling; just logo inside */
}
.footer-logo img {
  max-width: 250px;
  height: auto;
  display: block;
  opacity: 0.4;
  cursor: pointer;
}

/* ----- Center (Social images) ----- */
.footer-center {
  display: flex;
  align-items: center;       /* Vertically center icons themselves */
  justify-content: center;   /* Center horizontally */
  gap: 20px;
  flex: 1;     
    padding-left: 150px;
            /* Let center expand if needed */
}
.footer-center img {
  max-width: 50px;
  height: auto;
  opacity: 0.75;
  transition: opacity 0.3s ease;
  cursor: pointer;
}
.footer-center img:hover {
  opacity: 1;
}

/* ----- Right (Grid columns) ----- */
.footer-right {
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, auto));
  column-gap: 50px;
  row-gap: 30px;
  margin-left: 40px; /* optional spacing so icons aren’t too close */
}
.footer-column h3 {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 1.1rem;
}
.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-column li {
  margin-bottom: 8px;
}
.footer-column a {
  color: #ccc;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 0.95rem;
}
.footer-column a:hover {
  color: #fff;
}

/* ----- Bottom Row ----- */
.footer-bottom {
  width: 100%;
  margin-top: 40px;
  text-align: center;
}
.footer-bottom p {
  margin-bottom: 5px;
  font-size: 0.85rem;
}
.bottom-links {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
  margin-top: 5px;
}
.bottom-links p {
  cursor: pointer;
  font-size: 0.85rem;
  text-decoration: underline;
  transition: color 0.3s ease;
  margin: 0;
}
.bottom-links p:hover {
  color: #5e17eb;
}

/* ----- Responsive for <= 1170px ----- */
@media (max-width: 1170px) {
  .footer {
    /* Switch to vertical stacking */
    display: flex;
    flex-direction: column;
    align-items: center;   /* center everything horizontally */
    padding: 20px;
  }

  /* Hide the right columns entirely on mobile */
  .footer-right {
    display: none;
  }

  /* Stack footer-left (logo), then footer-center (icons) */
  .footer-left {
    margin-bottom: 20px;   /* spacing between sections */
    text-align: center;    /* center them */
  }

  .footer-center {
    margin-bottom: 20px;   /* spacing between sections */
    text-align: center;    /* center them */
    margin-right: 150px;
  }

  /* Optionally, tweak the logo size so it fits better on mobile */
  .footer-logo img {
    max-width: 180px;
    opacity: 0.6;
  }

  /* The bottom row remains at the bottom, also centered */
  .footer-bottom {
    margin-top: 20px;
  }

  .footer-center img {
    width: 50px;
    height: auto;
  }
}

@media (max-width: 560px) {
  .footer-logo img {
  width: 100px;
  height: auto;
  }
  
  .footer-center img {
    width: 30px;
    height: auto;
  }

  .footer-center {
    margin-right: 0px;
    margin-left: -150px;
  }
}