.home-page-containe{
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  font-family: 'Poppins';
  overflow-x: hidden;
}

.home-page {
  position: relative;
}

.dashboard-container {
  position: relative;
  z-index: 1;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.about-us-container {
  position: relative;
  z-index: 1;
  transition: transform 0.5s ease, opacity 0.5s ease;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  margin-top: 200px;
}

.how-it-works-container {
  position: relative;
  margin-top: 0px;
  width: 100%;
}

.how-it-works-title {
  position: sticky;
  top: 70px;
  z-index: 5;
  width: 100%;
  font-size: 20px;
  background-color: white;
  padding: 0;
  text-align: center;
  color: black;
  transition: transform 0.5s ease;
}

.how-it-works-section {
  padding: 50px 0;
}

.section {
  margin: 100px 0;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; 
  height: 100vh;
  z-index: 9999;
  background-color: #e6e8f8; /* your desired background color */
  display: flex;             /* center the video horizontally/vertically */
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease;
}

.loading-overlay.fade-out {
  opacity: 0;
}

/* Video should fill the screen’s width while maintaining its ratio */
.loading-video {
  width: 100vw;  /* Use full viewport width */
  height: auto;  /* Keep aspect ratio */
  object-fit: contain;
  /* 'contain' ensures no cropping (so full width is visible),
     letterboxing will appear above/below if the video’s aspect ratio 
     is different than the device’s aspect ratio. */
}

.page123 {
  height: 100vh;
}

.page12 {
  height: 100vh;
}

@media (max-width: 1400px) {
  .page435 {
    display: none;
  }
}