/* SignIn.css */

/* The container replicates MergedSignUp's .signup-container */
.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 80px auto; /* center on page */
  padding: 40px;
  font-family: 'Poppins';
  background-color: #e6e8f8; /* optional: white background */
  border-radius: 8px;      /* optional: subtle corner radius */
}

.signup-container h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  margin-top: 50px;
}

/* Inputs (email & password) mimic MergedSignUp styling */
input[type='email'],
input[type='password'] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: none;
  background-color: #f6f7ff;
  border-radius: 8px;
  font-size: 1rem;
  height: 40px;
  font-family: 'Poppins', Arial, sans-serif;
  box-sizing: border-box;
}

/* Main Sign In button (like .continue-button) */
.continue-button {
  width: 100%;
  height: 50px;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: 100;
  margin-bottom: 15px;
}

.continue-button:hover {
  background-color: #333;
}

/* Forgot password link */
.forgot-password {
  font-size: 0.9rem;
  color: #6e44ff;
  cursor: pointer;
  margin-top: 5px;
  text-decoration: underline;
  text-align: center;
}

.forgot-password:hover {
  text-decoration: none;
}

/* Divider (same as in MergedSignUp) */
.divider {
  width: 100%;
  margin: 20px 0;
  border: 0.5px solid #ccc;
}

/* Social buttons (Apple, Google) same from MergedSignUp */
.social-button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 1rem;
  border: 1px solid #ccc;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  background-color: #f6f7ff;
  color: black;
  font-family: 'Poppins', Arial, sans-serif;
}

.social-button:hover {
  background-color: #b0b1b7;
}

.social-logo {
  width: 20px;
  height: auto;
  margin-right: 20px;
  margin-left: 10px;
  margin-bottom: -3px;
}

/* We reuse the same apple-button & google-button classes from MergedSignUp */
.apple-button,
.google-button {
  width: 100%;
  height: 50px;
  padding: 10px;
  background-color: #f6f7ff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  font-family: 'Poppins', Arial, sans-serif;
  margin-bottom: 15px;
}

/* Error & success messages (similar to MergedSignUp) */
.error-text {
  color: red;
  margin-top: 5px;
  margin-bottom: 10px;
  font-family: 'Poppins', Arial, sans-serif;
  text-align: center;
}

.success-text {
  color: green;
  margin-top: 5px;
  margin-bottom: 10px;
  font-family: 'Poppins', Arial, sans-serif;
  text-align: center;
}

/* Optional: responsive tweaks */
@media (max-width: 768px) {
  .signup-container {
    width: 90%;
    padding: 20px;
    margin: 40px auto;
  }

  .continue-button {
    font-size: 1rem;
    height: 45px;
  }
}
