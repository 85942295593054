/****************************************
  Reset
****************************************/
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/****************************************
  Body (if needed)
****************************************/
body {
  font-family: "Poppins", sans-serif;
  background: #e6e8f8; /* Light purple background */
  color: #333;
  overflow-x: hidden;
}

/****************************************
  Loading Overlay
****************************************/
.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(230,232,248, 0.95); /* Slightly translucent */
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingSpinner {
  width: 50px;
  height: 50px;
  border: 6px solid #333;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/****************************************
  Documentation Container
****************************************/
.documentationContainer {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden; /* hides any x-overflow */
}

/****************************************
  Main Content
****************************************/
.mainContent {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px 20px;
  opacity: 0; /* start hidden, revealed via GSAP */
}

/****************************************
  Header
****************************************/
.documentationHeaderSection {
  text-align: center;
  margin-bottom: 20px;
}

.documentationPageTitle {
  font-size: 2.5rem;
  color: #333;
  font-weight: 700;
}

/****************************************
  Section Titles
****************************************/
.sectionSubtitle {
  font-size: 1.4rem;
  color: #333;
  font-weight: 600;
  margin: 30px 0 10px;
}

/****************************************
  General Text
****************************************/
.sectionText {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  margin-bottom: 20px;
}

/****************************************
  Intent Section
****************************************/
.documentationIntentSection {
  margin-top: 20px;
}

.documentationIntentSection .sectionText {
  font-size: 1rem;
  color: #666;
}

/****************************************
  Scope of Coverage Section
****************************************/
.documentationScopeSection {
  margin-top: 40px;
}

.documentationScopeList {
  list-style: disc;
  margin-left: 40px;
  margin-top: 10px;
}

.documentationScopeList li {
  margin-bottom: 10px;
  color: #555;
  line-height: 1.4;
}

/****************************************
  Limitations Section
****************************************/
.documentationLimitationsSection {
  margin-top: 40px;
}

.documentationLimitationsList {
  list-style: disc;
  margin-left: 40px;
  margin-top: 10px;
}

.documentationLimitationsList li {
  margin-bottom: 10px;
  color: #555;
  line-height: 1.4;
}

/****************************************
  User Discretion Section
****************************************/
.documentationUserDiscretionSection {
  margin-top: 40px;
}

/****************************************
  Media Queries
****************************************/
@media (max-width: 600px) {
  .documentationPageTitle {
    font-size: 2rem;
  }

  .sectionSubtitle {
    font-size: 1.2rem;
  }
}
