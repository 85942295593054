

.testimonial-wrapper {
  /* Remove or comment out the margin-bottom if you want true centering */
  /* margin-bottom: 200px; */

  /* Ensure it occupies full viewport height */
  min-height: 100vh;

  /* Use Flexbox to center vertically */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Use align-items: center if you also want *horizontal* centering: */
  /* align-items: center; */

  /* You can keep any padding or max-width you need */
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem 1rem;
  font-family: 'Poppins', sans-serif;
}


/* NEW HEADER SECTION */
.highlight-header {
  margin-bottom: 80px; /* space below the header before the testimonial */
}

/* Example styling for the header */
.highlight-header h1 {
  font-size: 2.3rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.highlight-header p {
  font-size: 1.5rem;
  color: #555;
}

/* Container for testimonial component */
.testimonial-container {
  display: flex;
  margin: 0 auto; /* center it on the page horizontally */
}

/* Left titles column */
.testimonial-titles {
  width: 35%;
  padding-right: 2rem;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Individual title blocks */
.testimonial-title {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.testimonial-title:hover {
  transform: translateX(5px);
}

/* Highlight the active testimonial */
.testimonial-title.active .title-label {
  color: #6d3efc; /* highlight color for the active one */
  font-weight: 600;
}

/* Subtext under the label */
.title-subtext {
  font-size: 1.4rem;
  color: #777;
  margin-top: 0.25rem;
  line-height: 1.4;
}

/* Right side: the slider */
.testimonial-slider {
  position: relative;
  width: 65%;
  overflow: hidden;
}

/* Wrapper that holds all the slides side by side */
.slides-wrapper {
  display: flex;
  transition: transform 0.6s ease;
  /* If you have 3 slides total, you might do width: 300%. 
     But you used transform: translateX(-${activeIndex * 34.5}%). 
     Tweak as needed. */
  width: 300%;
}

/* Each individual slide */
.slide {
  flex: 0 0 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  min-height: 100%;
  max-width: 900px;
}

.slide h2 {
  font-size: 1.7rem;
  margin-bottom: 1rem;
  font-weight: 600;
  text-align: justify;
}

.slide p {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: justify;
}

  /* Label for left column title */
  .title-label {
    
    color: black;
    border-radius: 25px;
    padding: 6px 20px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-size: 1rem;
    width:fit-content;
    border: 4px solid black;
    transition: background-color 0.3s;
  }
 
  /* Mobile-friendly adjustments */
@media (max-width: 768px) {
  /* Stack columns vertically */
  .testimonial-container {
    flex-direction: column;
    align-items: flex-start; /* or center if you prefer centered alignment */
  }

  /* Make the titles column full width; move border to bottom */
  .testimonial-titles {
    width: 100%;
    padding-right: 0; 
    border-right: none;
    border-bottom: 1px solid #ddd;
    margin-bottom: 1.5rem;
  }

  /* Slider also takes full width */
  .testimonial-slider {
    width: 100%;
    margin: 0 auto; 
  }

  /* If you’re using 33.5% increments for each slide, keep that.
     But ensure each slide never exceeds the screen width */
  .slides-wrapper {
    width: 300%; /* For 3 slides, as you have. */
  }

  .slide {
    max-width: 100%;  /* Don’t exceed parent width */
    padding: 0 1rem;
  }

  /* Adjust heading and paragraph sizes for smaller screens */
  .highlight-header h1 {
    font-size: 1.8rem;
  }

  .highlight-header p {
    font-size: 1.2rem;
  }

  .slide h2 {
    font-size: 1.1rem;
  }
  
  /* Title label can shrink a bit */
  .title-label {
    font-size: 0.9rem;
    border: 3px solid black; /* slightly thinner border on mobile */
  }
}
