/****************************************
  Reset
****************************************/
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/****************************************
  Body (if needed)
****************************************/
body {
  font-family: "Poppins", sans-serif;
  background: #e6e8f8; /* Light purple background */
  color: #333;
  overflow-x: hidden;
}

/****************************************
  Loading Overlay
****************************************/
.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(230,232,248, 0.95); /* Slightly translucent */
  z-index: 9999; /* On top of everything */
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingSpinner {
  width: 50px;
  height: 50px;
  border: 6px solid #333;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/****************************************
  Features Container
****************************************/
.featuresContainer {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden; /* hides any x-overflow */
}

/****************************************
  Main Content
****************************************/
.mainContent {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px 20px;
  opacity: 0; /* Start hidden, revealed via GSAP */
}

/****************************************
  Header
****************************************/
.featuresHeaderSection {
  text-align: center;
  margin-bottom: 20px;
}

.featuresPageTitle {
  font-size: 2.5rem;
  color: #333;
  font-weight: 700;
}

/****************************************
  Section Titles
****************************************/
.sectionSubtitle {
  font-size: 1.4rem;
  color: #333;
  font-weight: 600;
  margin: 30px 0 10px;
}

/****************************************
  General Text
****************************************/
.sectionText {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  margin-bottom: 20px;
}

/****************************************
  Overview Section
****************************************/
.featuresOverviewSection {
  margin-top: 20px;
}

.featuresOverviewSection .sectionText {
  font-size: 1rem;
  color: #666;
}

/****************************************
  Features List Section
****************************************/
.featuresListSection {
  margin-top: 40px;
}

/****************************************
  Feature Card
****************************************/
.featureCard {
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.08);
  padding: 20px 24px;
  margin-bottom: 20px;
  opacity: 1; /* The cards themselves can be animated in with GSAP */
}

.featureTitle {
  font-size: 1.2rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
}

.featureDescription {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
  line-height: 1.4;
}

.protectiveDisclaimer {
  font-size: 0.9rem;
  color: #666;
  font-style: italic;
  line-height: 1.3;
  margin-top: 8px;
}

/****************************************
  Legal & Liability Sections
****************************************/
.legalSection,
.liabilitySection {
  margin-top: 40px;
}

.legalPoints,
.liabilityPoints {
  list-style: disc;
  margin-left: 40px;
  margin-top: 10px;
}

.legalPoints li,
.liabilityPoints li {
  margin-bottom: 10px;
  color: #555;
}

/****************************************
  Media Queries
****************************************/
@media(max-width: 600px) {
  .featuresPageTitle {
    font-size: 2rem;
  }

  .sectionSubtitle {
    font-size: 1.2rem;
  }
}
