/* Fullscreen dark backdrop */
.loaderBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(20, 20, 20, 0.9);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* The container for the image logo */
  .loaderLogoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* The actual logo image */
  .loaderLogoImage {
    width: 300px;    /* adjust as needed */
    height: auto;
    object-fit: contain;
  }
  