/* =========== GLOBAL STYLES =========== */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
  }
  
  /* =========== CONTAINER =========== */
  .refer-friend-container {
    position: relative; /* so we can position confetti overlay absolutely */
    width: 100%;
    max-width: 1400px;
    margin: 80px auto;
    text-align: left;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  /* =========== TEXT STYLES =========== */
  .refer-title {
    margin: 0;
    font-size: 2.2rem;
    font-weight:500;
    color: #333;
  }
  
  .refer-subtitle {
    font-size: 1.8rem;
    color: #666;
    margin: 15px 30px 40px;
  }
  
  /* Wrapper that holds input + Copy button side by side */
.referral-link-container {
    display: flex;            /* Put elements on the same row */
    align-items: left;      /* Vertically center them */
    width: 100%;
    max-width: 400px;         /* Or whatever width you prefer */
    margin: 0 auto 0px;      /* Center horizontally + spacing at bottom */
    box-sizing: border-box;
  }
  
  /* The text input */
  .referral-link-input {
    flex: 1;                  /* Let the input grow to fill remaining space */
    height: 44px;             /* Fixed height so it matches the button */
    padding: 0 12px;  
    background-color: #ccc !important;        /* Horizontal padding for comfort */
    border: 1px solid #ccc;
    border-right: none;       /* Avoid double-border between input & button */
    border-radius: 6px 0 0 6px; 
    font-size: 1rem;
    color: #333;
    box-sizing: border-box;
    outline: none;
  }
  
  /* The Copy button */
  .copy-button {
    height: 44px;            /* Match input’s height */
    padding: 0 16px;     
    margin-bottom: 15px;    /* Usually a bit more horizontal padding */
    border: 1px solid #ccc;
    border-left: none;       /* Avoid double-border between input & button */
    border-radius: 0 6px 6px 0;
    background-color: #6b44e7;
    color: #fff;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    box-sizing: border-box;
  }
  
  .copy-button:hover {
    background-color: #5a37c4;
  }
  
  
  /* =========== REFER NOW BUTTON =========== */
  .refer-now-wrapper {
    display: flex;
    justify-content: center;
  }
  
  .refer-now-button {
    position: relative;
    padding: 8px 24px;
    border-radius: 24px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    color: #fff;
    background-color: black;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  /* =========== CONFETTI OVERLAY =========== */
  .confetti-overlay {
    pointer-events: none;  /* let clicks pass through */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;      /* hide confetti that goes outside container */
    z-index: 999;         /* above everything */
  }
  
  /* Each confetti piece */
  .confetti {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    animation: floatAndRotate 2s ease-in-out infinite;
    opacity: 0;
    transform: translate(-50%, -50%) rotate(0deg);
    /* You could also randomize transform + rotation in JS if you want more variety */
  }
  
  /* Confetti animation:
     - fade in & out
     - random rotation
     - random vertical/horizontal movement
  */
  @keyframes floatAndRotate {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) rotate(0deg) scale(0.8);
    }
    10% {
      opacity: 1;
      transform: translate(-40%, -70%) rotate(45deg) scale(1);
    }
    50% {
      transform: translate(30%, 50%) rotate(180deg) scale(1.2);
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translate(60%, 120%) rotate(360deg) scale(0.7);
    }
  }
  
  /* =========== MEDIA QUERIES =========== */
  @media (max-width: 500px) {
    .refer-title {
      font-size: 1.7rem;
    }
    .refer-subtitle {
      font-size: 0.95rem;
    }
    .referral-link-input {
      width: 70%;
      font-size: 0.95rem;
    }
    .copy-button {
      font-size: 0.9rem;
      padding: 10px 16px;
    }
    .refer-now-button {
      font-size: 0.95rem;
      padding: 12px 20px;
    }
    .confetti {
      width: 30px;
      height: 30px;
    }
  }
  