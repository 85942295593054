/* Container for all chats */
.chats-container {
  max-width: 1600px; 
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  justify-content: flex-start;
  height: calc(100vh - 120px);
  padding: 40px;
  background-color: #e6e8f8;
}

/* Main title (Business Name) */
.chats-container h1 {
  font-size: 36px;
  color: black;
  margin-bottom: 10px;
  font-family: 'Poppins';
  text-align: left;
}

/* Subtitle (Collaborate) */
.chats-container h2 {
  font-size: 28px;
  color: #000;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: left;
}

/* Chat items container */
.chat-items-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

/* Each chat item box */
.chat-item {
  background: #fff;
  border: 1px solid #ccc;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border-radius: 20px;
  display: flex;
  cursor: pointer;
  position: relative; 
}

/* If there's an unread message => highlight border */
.chat-item.unread {
  border: 2px solid #5e17eb;
}

/* The container for the icon + unread badge */
.icon-bubble {
  position: relative;
  width: 50px;   /* match the .chat-icon size */
  height: 50px;  /* match the .chat-icon size */
  margin-right: 15px;
}

/* The icon itself */
.chat-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

/* The unread badge pinned to top-right of the icon */
.unread-badge {
  position: absolute;
  top: -4px;      /* adjust to your preference */
  right: -4px;    /* adjust to your preference */
  background-color: #5e17eb;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: bold;
}

/* The text container beside the icon */
.chat-text-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Top row: user name on left, time on right */
.chat-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Name of the user (Student) */
.chat-user {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

/* Time label */
.chat-time {
  font-size: 14px;
  color: #555;
}

/* Project name below user’s name */
.chat-project {
  font-size: 14px;
  color: #000;
  margin: 5px 0;
}

/* Last message preview */
.chat-last-message {
  font-size: 14px;
  color: #555;
  margin-top: 4px;
}

@media(max-width: 1400px) {
  .chat-item {
    width: 70%;
  }
}

@media(max-width: 1200px) {
  .chat-item {
    width: 80%;
  }
}

@media(max-width: 600px) {
  .chat-item {
    width: 95%;
  }
}
