/* src/StudentDash.css */

/* Root container */
.business-dash-container67 {
  display: flex;
  flex-direction: row;
  height: calc(100vh);
  margin-top: -80px;
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
  position: relative;
}
.business-dash-container67.loaded67 {
  opacity: 1;
}
.business-dash-container67.expanded-mode67 {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 130px);
  margin-top: 0px;
  transition: opacity 0.8s ease-in-out;
  position: relative;
}

/* SIDEBAR */
.sidebar67 {
  width: 25%;
  background-color: #fff;
  padding: 120px 20px; 
  box-sizing: border-box;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 80px; 
  transition: width 0.4s ease, padding 0.4s ease;
  overflow: hidden;
  position: relative;
}
.sidebar67.collapsed67 {
  width: 0;
  padding: 0;
}

.section67 {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin: 0;
  padding: 0;
}
.slash-heading67 {
  font-size: 1.4rem;
  font-weight: 900;
  color: #6e44ff;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}
.slash-heading67.clicked67 {
  color: #000;
  transform: translateY(-4px);
}
.slash-heading67:hover {
  color: #000;
}

/* Blog previews */
.blog-previews67 {
  margin-top: 10px;
}
.blog-preview-item67 {
  cursor: pointer;
  font-weight: 900;
  font-size: 1.3rem;
  margin-bottom: 5px;
}
.blog-preview-item67:hover {
  font-weight: 500;
}
.blog-preview-item67.second-item67 {
  opacity: 0.75;
}
.blog-preview-item67.third-item67 {
  opacity: 0.55;
}

/* Buttons */
.card-button3167,
.card-button31267 {
  background-color: #000;
  color: white;
  border: none;
  padding: 5px 30px;
  border-radius: 35px;
  font-size: 1.1rem;
  font-family: 'Poppins';
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 190px;
}
.card-button3167:hover,
.card-button31267:hover {
  background-color: #333;
}
.card-button31267 {
  margin-top: 0;
  margin-bottom: 15px;
}

/* MAIN CONTENT */
.main-content67 {
  width: 70%;
  background-color: #e6e8f8;
  padding: 120px 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 30px;
  transition: width 0.4s ease, padding 0.4s ease;
  position: relative;
}
.main-content67.fullwidth67 {
  width: 100%;
  padding: 40px 80px;
}

/* The nav dots => now 3 dots */
.nav-dots67 {
  position: absolute;
  top: 180px;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  cursor: pointer;
  transition: left 0.4s ease;
}
.dot67 {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.purple-dot67 {
  background-color: #6e44ff;
}
.grey-dot67 {
  background-color: #c4c4c4;
}

/* Titles, ongoing projects */
.welcome-title67 {
  font-size: 2.4rem;
  font-weight: 700;
  color: #3b3b3b;
}
.ongoing-projects-section67 {
  margin-bottom: 20px;
}
.ongoing-title67 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}
.ongoing-title167 {
  font-size: 1.5rem;
  margin-bottom: 0px;
}

/* Chats or fullwidth sections */
.chats-fullwidth67 {
  width: 100%;
}
.project-fullwidth67 {
  width: 100%;
  margin-top: -200px;
}
.account-fullwidth67 {
  width: 100%;
}
.projectBoard-fullwidth67 {
  width: 100%;
}

/* Chat items */
.chat-item67 {
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-bottom: 12px;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
}
.chat-item67:hover {
  background-color: #f2f2f2;
}
.chat-icon67 {
  width: 50px;
  height: 50px;
  margin-right: 12px;
  border-radius: 50%;
}
.chat-text-container67 {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  gap: 4px;
}
.chat-header-row67 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chat-user67 {
  font-weight: 700;
}
.chat-time67 {
  color: #999;
  font-size: 0.8rem;
}
.chat-project67 {
  font-size: 1rem;
  font-weight: 500;
  color: #666;
}
.chat-last-message67 {
  font-size: 0.9rem;
  color: #888;
}

/* Responsive */
@media (max-width: 900px) {
  .business-dash-container67 {
    flex-direction: column;
    margin-top: 0px;
    height: calc(100vh - 160px);
    padding: 10px;
  }
  .main-content67 {
    width: 100%;
    padding: 0px 10px;
  }
  .main-content67.fullwidth67 {
    width: 100%;
    padding: 0px 0px;
  }
  .sidebar67 {
    display: none;
  }
  .nav-dots67 {
    display: none;
  }
}
