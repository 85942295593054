/* Container for all chats */
.chats-container79 {
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: calc(100vh - 120px);
  padding: 40px;
  background-color: #e6e8f8;
}

/* Main title (Name | University) */
.chats-container79 h1 {
  font-size: 36px;
  color: black;
  margin-bottom: 10px;
  font-family: 'Poppins';
  text-align: left;
}

/* Subtitle (Collaborate) */
.chats-container79 h2 {
  font-size: 28px;
  color: #000;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: left;
}

/* Chat items container */
.chat-items-container79 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

/* Each chat item */
.chat-item79 {
  background: #fff;
  border: 1px solid #ccc;
  width: 90%;
  border-radius: 20px;
  padding: 0.75rem;
  margin-bottom: 1rem;
  display: flex;
  cursor: pointer;
  transition: background 0.3s ease; 
  /* no need for position: relative here if the badge is inside .icon-bubble79 */
}

/* If there are unread messages => highlight border color */
.chat-item79.unread {
  border: 2px solid #5e17eb;
}

/* The icon bubble for the profile pic + unread badge */
.icon-bubble79 {
  position: relative; 
  width: 50px;  
  height: 50px; 
  margin-right: 15px; 
}

/* The icon itself */
.chat-icon79 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

/* The unread badge pinned to top-right corner of the icon */
.unread-badge79 {
  position: absolute;
  top: -4px;     /* adjust to your preference */
  right: -4px;   /* adjust to your preference */
  background-color: #5e17eb;
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 50%;
  width: 20px;       
  height: 20px;      
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Container for text details to the right */
.chat-text-container79 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chat-header-row79 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.chat-user79 {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.chat-time79 {
  font-size: 14px;
  color: #555;
}

.chat-project79 {
  font-size: 14px;
  color: #000;
  margin: 5px 0;
}

.chat-last-message79 {
  font-size: 14px;
  color: #555;
  margin-top: 4px;
}

/* Additional responsiveness */
@media (max-width: 1400px) {
  .chat-item79 {
    width: 70%;
  }
}

@media (max-width: 1200px) {
  .chat-item79 {
    width: 80%;
  }
}

@media (max-width: 600px) {
  .chat-item79 {
    width: 95%;
  }
}
