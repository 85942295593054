/* Background Video */
.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.dashboard-container1234 {
  position: relative;
  background-color: black;
  font-family: 'Poppins';
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: -400px;
  transition: opacity 1s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.dashboard-container11.loaded {
  opacity: 1;
}

.d123 {
  position: relative;
  box-sizing: border-box;
display: flex;
flex-direction: column;
justify-content: space-between;
box-sizing: border-box;
overflow: hidden;
padding-left: 200px;
padding-right: 200px;
}

.upper-section11 {
  position: relative;
  padding: 0 20px;
  margin-top: 0px;
}

.dashboard-header11 {
  opacity: 0;
  animation: fadeIn 2s ease-in-out 1s forwards;
  flex: 1;
  display: flex;
  width: auto;
  flex-direction: column;
  align-items: flex-start;
}

.additional-links11 {
  margin-top: 10px;
}

.dashboard-header11 .additional-links11 p {
  margin: 5px 0;
  font-size: 1.8rem;
  font-family: 'Poppins';
  color: #706f6f;
  cursor: pointer;
  transition: color 0.3s ease;
}

.dashboard-header11 .additional-links11 p:hover {
  color: #000;
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.dashboard-header11 h1 {
  font-size: 3rem;
  margin: 0;
  color: #000;
  font-family: Poppins;
  cursor: pointer;
}

.dashboard-header11 h1.student11 {
  font-size: 2.5rem;
  margin: 5px 0;
  color: #6e44ff;
}

.dashboard-header11 h1.home11 {
  font-size: 2.5rem;
  color: #000;
}

.welcome-message11 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 1;
  animation: fadeInWelcome 2s ease-in-out forwards;
}

@keyframes fadeInWelcome {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.welcome-message11 h2 {
  font-size: 4rem !important;
  font-weight: 700 !important;
  margin: 0;
  color:white !important;
  font-family: Poppins;
}

.welcome-message11 h2 span {
  color: #6e44ff;
}

.welcome-message11 p {
  color: white;
  font-size: 1.7rem;
}

.mobile-only {
  display: none;
}

@media (max-width: 1160px) {
  .dashboard-header11 {
      display: none;
  }

  .upper-section11 {
      margin-top: 60px;
      height: auto;
  }

  .welcome-message11 {
      position: static;
      transform: none;
      text-align: center;
      margin-top: 20px;
  }


  .mobile-only {
      display: flex;
  }

  .dashboard-links11 {
      flex-direction: column;
      align-items: center;
  }

  .link-item11 {
      margin-bottom: 20px;
  }
}

@media (max-width: 800px) {
  .d123 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .welcome-message11 h2{
    margin-top: 200px !important;
font-size: 2rem !important;
  }

  .welcome-message11 p{
font-size: 1.2rem !important;
  }
}

@media (max-width: 3600px) {
  .d123 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
