.success-page-container {
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  margin: 0 auto;
  min-height: 100vh;
  background-color: #e6e8f8; /* Adjust background to match the screenshot’s lighter hue */
  font-family: 'Poppins', sans-serif;
  position: relative;
  align-items: left;
  text-align: left;
  padding: 10px;
}

.success-page-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  position: relative;
  z-index: 1;
}

h1 {
  font-size: 3.5rem;
  font-weight: bold;
  text-align: left;
  color: #333;
  margin-bottom: 1rem;
}

.live-text {
  font-size: 3rem;
  margin-top: 200px;
  text-align: left;
  color: #706f6f;
  margin-bottom: 0.5rem;
}

.experience-text {
  font-size: 2rem;
  text-align: left;
  color: #706f6f;
  margin-bottom: 2rem;
  opacity: 0.7;
}

.project-details {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 2rem;
  text-align: left;
}

.project-title-budget {
  margin-bottom: 1rem;
}

.project-title-budget h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.project-title-budget p {
  font-size: 1.8rem;
  color: #706f6f;
}

.my-projects-button {
  font-size: 1.8rem;
  font-weight: bold;
  background: none;
  border: none;
  color: #000;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

/* Arrow icon styling, if you need it */
.my-projects-button .arrow {
  margin-left: 10px;
  font-size: 2rem;
  color: #6e44ff;
  transition: transform 0.2s ease;
}

.my-projects-button:hover .arrow {
  transform: translateX(5px);
}

/* Mobile Responsiveness */
@media (max-width: 600px) {
  .success-page-content h1 {
    font-size: 1.7rem;
  }
  .live-text {
    margin-top: 40px;
    font-size: 1.4rem;
  }
  .experience-text {
    font-size: 1rem;
    margin-bottom: 40px;
  }
  .project-title-budget h2 {
    font-size: 1.8rem;
  }
  .project-title-budget p {
    font-size: 1.4rem;
  }
  .my-projects-button {
    font-size: 1.4rem;
  }
}
