/* src/components/MyStudentProjects.css */

.project-container {
  font-family: Poppins;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center */
  align-items: center;     /* Horizontally center */
  height: 100vh;           /* Full viewport height */
  margin: 0 auto;
  text-align: center;
}

.project-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.indicator {
  width: 10px;
  height: 10px;
  background-color: #706f6f;
  border-radius: 50%;
  margin: 0 5px;
}

.indicator.active {
  background-color: #6e44ff;
}

.project-info {
  text-align: center;
  margin-bottom: 20px;
}

.project-info h2 {
  font-size: 2rem;
  color: #000;
  margin: 0;
}

.project-price {
  font-size: 1.2rem;
  color: #706f6f;
  margin: 5px 0;
}

.project-summary {
  margin-bottom: 20px;
  max-width: 600px; /* Limit width for readability */
}

.project-summary p {
  font-size: 1.5rem;
  color: #000;
  margin: 0;
}

.project-collaborator {
  display: flex;
  align-items: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.project-collaborator span {
  font-size: 2rem;
  color: #000;
  margin-right: 10px;
}

.project-collaborator h3 {
  font-size: 2rem;
  color: #000;
  margin: 0;
}

.collaborate {
  margin-bottom: 20px;
}

.collaborate p {
  font-size: 1.8rem;
  color: #706f6f;
  font-weight: bold;
  cursor: pointer;
}

.arrow-container {
  cursor: pointer;
  margin-top: 20px;
}

.arrow {
  font-size: 2rem;
  color: #6e44ff;
}

.no-projects {
  font-size: 1.5rem;
  color: #000;
  margin-top: 20px;
}
