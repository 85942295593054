/* This file is imported by GlobalCursor.jsx */

/* The ring’s base style */
.cursor-ring {
    position: fixed;
    width: 20px;
    height: 20px;
    pointer-events: none;
    border-radius: 50%;
    z-index: 9999;
    transform: translate(-50%, -50%);
    /* Only transition color/size changes, not position */
    transition: width 0.2s, height 0.2s, background-color 0.2s, border 0.2s;
  }
  
  /* Dark ring (for light backgrounds) => black fill */
  .cursor-ring.dark {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  /* Light ring (for dark backgrounds) => white fill */
  .cursor-ring.light {
    background-color: rgba(255, 255, 255, 0.9);
  }
  
  /* 
    When hovering over something clickable + background is light mode: 
    Enlarge ring, transparent fill, white border
  */
  .cursor-ring.light.pointer-active {
    width: 34px;
    height: 34px;
    background-color: transparent;
    border: 2px solid white;
  }
  
  /* 
    When hovering over something clickable + background is dark mode:
    Enlarge ring, transparent fill, black border
  */
  .cursor-ring.dark.pointer-active {
    width: 34px;
    height: 34px;
    background-color: transparent;
    border: 2px solid black;
  }
  