/* 1) Full-page container with white background */
.tsFullBg {
    background-color: #fff; /* White only for this T&C page */
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  /* 2) The Terms container */
  .tsContainer {
    max-width: 1000px; /* or 1400px if desired */
    width: 100%;
    margin: 40px 20px; 
    background: #fff;
    border-radius: 8px;
    padding: 40px;
    font-family: 'Poppins', sans-serif;
    color: #333;
    line-height: 1.6;
  }
  
  /* Title / Heading style */
  .tsHeading {
    font-size: 2rem;
    font-weight: 700;
    color: #333;
    text-align: center;
    margin-bottom: 30px;
  }
  
  /* Effective date styling */
  .effectiveDate {
    font-size: 0.95rem;
    color: #666;
    text-align: center;
    margin-bottom: 30px;
  }
  
  /* Each T&C section */
  .tsSection {
    margin-bottom: 30px;
  }
  
  .tsSection h2 {
    font-size: 1.2rem;
    margin-bottom: 8px;
    color: #6e44ff; /* Moccet brand accent color */
    font-weight: 600;
  }
  
  .tsSection p {
    margin-bottom: 10px;
  }
  
  .tsSection ul {
    list-style: disc;
    margin-left: 1.5em;
    margin-bottom: 10px;
  }
  
  .tsSection ul li {
    margin-bottom: 6px;
  }
  
  /* Final note styling */
  .closingNote {
    margin-top: 20px;
    font-style: italic;
    color: #444;
  }
  