.business-project-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: Poppins;
  }
  
  .project-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    gap: 20px;
  }
  
  .project-info {
    text-align: center;
  }
  
  .project-title {
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .project-budget {
    font-size: 1.5rem;
    color: gray;
  }
  
  .project-summary {
    text-align: center;
    max-width: 600px;
    font-size: 1.2rem;
  }
  
  .collaboration-info {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .review-applications {
    cursor: pointer;
    color: #6e44ff;
  }
  
  .arrow-left, .arrow-right {
    font-size: 2.5rem;
    cursor: pointer;
    color: #6e44ff;
    transition: color 0.3s ease;
  }
  
  .arrow-left:hover, .arrow-right:hover {
    color: #4e32b3;
  }
  