/****************************************
  1) Full-Page White Container
****************************************/
.blogDetailFullPage {
    background-color: #fff; /* White only for this detail page */
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }
  
  /****************************************
    2) Wrapper for Actual Blog Content
  ****************************************/
  .blogDetailWrapper {
    width: 100%;
    max-width: 1200px; /* or 1400px if you prefer */
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  /****************************************
    3) Main Container Box
  ****************************************/
  .blogDetailContainer {
    background: #fff;
    border-radius: 12px;
    padding: 32px;
    box-sizing: border-box;
    width: 100%;
  }
  
  /****************************************
    4) Back Button
  ****************************************/
  .backButton {
    background: #eaeaea;
    color: #333;
    padding: 8px 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 24px;
    transition: background 0.3s ease;
  }
  
  .backButton:hover {
    background: #ccc;
  }
  
  /****************************************
    5) Title & Date
  ****************************************/
  .detailTitle {
    font-size: 3rem;
    margin-bottom: 10px;
    color: #333;
    font-weight: 700;
  }
  
  .blogDate {
    font-size: 0.9rem;
    color: #999;
    margin-bottom: 24px;
  }
  
  /****************************************
    6) detailContent (headings, bullets)
  ****************************************/
  .detailContent {
    font-size: 1.3rem;
    color: #444;
    line-height: 1.8;
  }
  
  .detailContent p {
    margin-bottom: 1.2em;
  }
  
  .detailContent h2,
  .detailContent h3 {
    margin-top: 2em;
    margin-bottom: 0.6em;
    font-weight: 600;
    color: #333;
    line-height: 1.3;
  }
  
  .detailContent hr {
    border: none;
    border-top: 1px solid #ddd;
    margin: 2em 0;
  }
  
  /* Bullets */
  .detailContent ul {
    list-style-type: disc;
    margin-left: 1.5em;
    margin-bottom: 1.5em;
  }
  
  .detailContent ul li {
    margin-bottom: 0.5em;
  }
  
  /* Numbered lists */
  .detailContent ol {
    list-style-type: decimal;
    margin-left: 1.5em;
    margin-bottom: 1.5em;
  }
  
  .detailContent ol li {
    margin-bottom: 0.5em;
  }
  

@media (max-width: 600px) {
    .detailTitle {
        font-size: 1.8rem;
    }

    .detailContent {
        font-size: 0.9rem;
    }

    .backButton {
        font-size: 0.8rem;
    }
}