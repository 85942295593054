/*******************************
   Base Layout & Styling
*******************************/
.hero-wrap {
    background-color: #e6e8f8;
    padding: 50px 20px;
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
  }
  
  /* Container for everything */
  .hero-container21 {
    background-color: #e6e8f8;
    padding: 100px 20px;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    width: 100%;
    position: relative;
  }
  
  /* Headline text: 3 lines */
  .hero-container21 h1 {
    margin: 0;
    font-size: 5rem;   /* Large text */
    font-weight: 700;  
    line-height: 1.7;  
    color: #000;
    
    /* Add a subtle text-shadow for a slight “glow” */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  
    /* Each <h1> uses the same cosmicIn animation but staggered by different delays */
    animation: cosmicIn 1.2s ease forwards;
    opacity: 0; /* Hidden until animated in */
  }
  
  /* Stagger the animation start times so they appear in sequence */
  .hero-container21 h1:nth-of-type(1) {
    animation-delay: 0.0s;
  }
  .hero-container21 h1:nth-of-type(2) {
    animation-delay: 0.3s;
  }
  .hero-container21 h1:nth-of-type(3) {
    animation-delay: 0.6s;
  }
  
  /* Container for the two buttons */
  .hero-buttons21 {
    margin-top: 40px;
    display: flex;
    gap: 20px;
    
    /* Animate the entire button group after headlines appear */
    animation: cosmicIn 1s ease forwards 1s; /* starts at 1s */
    opacity: 0;
  }
  
  /*******************************
     Button Base Styles
  *******************************/
  .use-moccet-ai21,
  .learn-more21 {
    border-radius: 30px;
    font-size: 1.1rem;
    padding: 15px 30px;
    cursor: pointer;
    position: relative; /* needed for shimmer effect */
    overflow: hidden;   /* hide the shimmer overflow */
    z-index: 1;         /* ensure pseudo-element is behind text */
    transition: transform 0.3s ease;
  }
  
  /* Use Moccet AI button: black background, white text */
  .use-moccet-ai21 {
    background-color: #000;
    color: #fff;
    border: none;
  }
  
  /* Learn More button: transparent with black border */
  .learn-more21 {
    background-color: transparent;
    color: #000;
    border: 2px solid #000;
    padding: 13px 28px; /* slightly smaller padding to match border thickness */
  }
  
  /*******************************
     Hover Shimmer for the Buttons
  *******************************/
  /* The 'shimmer' is an absolutely positioned radial gradient that rotates */
  .use-moccet-ai21::before,
  .learn-more21::before {
    content: "";
    position: absolute;
    top: -150%;
    left: -150%;
    width: 300%;
    height: 300%;
    background: radial-gradient(circle, rgba(255,255,255,0.3) 20%, transparent 60%);
    opacity: 0;
    transition: opacity 0.4s;
    animation: starShine 2.5s linear infinite;
  }
  
  /* On hover, show the shimmer */
  .use-moccet-ai21:hover::before,
  .learn-more21:hover::before {
    opacity: 1;
  }
  
  /* Subtle 3D “pop” on hover */
  .use-moccet-ai21:hover,
  .learn-more21:hover {
    transform: scale(1.04);
  }
  
  /*******************************
     Keyframes: Headline & Button Appear
  *******************************/
  @keyframes cosmicIn {
    100% {
      opacity: 1;
      transform: translateY(0) scale(1) rotate(0deg);
    }
  }
  
  /*******************************
     Keyframes: Button Shimmer Rotate
  *******************************/
  @keyframes starShine {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

  @media (max-width: 1370px) {
    .hero-container21 h1 {
        font-size: 4rem;
    }
  }

  @media (max-width: 1110px) {
    .hero-container21 h1 {
        font-size: 3rem;
    }
  }

  @media (max-width: 850px) {
    .hero-container21 h1 {
        font-size: 2.5rem;
    }

    .use-moccet-ai21,
    .learn-more21 {
      border-radius: 30px;
      font-size: 0.9rem;
      padding: 7px 20px;
      cursor: pointer;
      position: relative; /* needed for shimmer effect */
      overflow: hidden;   /* hide the shimmer overflow */
      z-index: 1;         /* ensure pseudo-element is behind text */
      transition: transform 0.3s ease;
    }
  }

  @media (max-width: 540px) {
    .hero-container21 h1 {
        font-size: 2rem;
    }
  }