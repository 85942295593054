/****************************************
  Reset
****************************************/
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/****************************************
  Body
****************************************/
body {
  font-family: "Poppins", sans-serif;
  background: #e6e8f8; /* Light purple background */
  color: #333;
  overflow-x: hidden;
}

/****************************************
  BlogList Container
****************************************/
.blogListContainer {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden; /* can still scroll vertically, but we hide x-overflow */
}

/****************************************
  Main Content
****************************************/
.mainContent {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
}

/****************************************
  Header
****************************************/
.blogMainHeader {
  text-align: center;
  padding: 40px 0 10px;
}
.blogMainHeader h1 {
  font-size: 2.5rem;
  color: #333;
  font-weight: 700;
}

/* Subheader: /Most Recent */
.blogSubHeader {
  text-align: left;
  margin-left: 20px;
  font-size: 1.1rem;
  font-weight: 600;
  color: #6e44ff;
  margin-bottom: 20px;
}

/****************************************
  Blog List
****************************************/
.listWrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 20px 80px;
}

/****************************************
  Blog Card
****************************************/
.blogCard {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.blogTitleText {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
}

/****************************************
  White Excerpt Bubble
****************************************/
.excerptBubble {
  background-color: #fff;
  border-radius: 16px;
  padding: 12px 16px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.08);
  /* optional: set a fixed height or let it auto-size 
     but we'll rely on text truncation for 1 line. */
}

/****************************************
  Single-Line Excerpt
****************************************/
.excerptText {
  font-size: 0.95rem;
  color: #666;
  line-height: 1.4;

  /* Force single line with ellipsis if too long */
  display: block;           /* or inline-block */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;  /* ensure it has a width to clamp the text */
}

/****************************************
  Bottom Screen Fade
****************************************/
.bottomScreenFade {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 33vh;   /* covers ~1/3 of the screen */
  pointer-events: none;
  z-index: 999;
  background: linear-gradient(
    rgba(230,232,248,0),
    rgba(230,232,248,1)
  );
  transition: opacity 0.4s ease;
}

/* fade out the gradient if user is near bottom of page */
.hideFade {
  opacity: 0;
}

@media(max-width: 600px) {
    .blogMainHeader h1 {
        font-size: 2rem;
    }
  }