/* Existing CSS */

/* =========== GLOBAL =========== */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #e6e8f8; 
  color: #000;
}

.applications-content {
  position: absolute;
  top: 50%; /* Positions the top of the element at 50% of the parent height */
  left: 50%; /* Positions the left of the element at 50% of the parent width */
  transform: translate(-50%, -50%); /* Offsets the element's position to truly center it */
  text-align: center;
  background-color: #e6e8f8;
  font-family: 'Poppins';
  opacity: 1; /* Ensure the message is visible */
  animation: fadeInWelcome 2s ease-in-out forwards;
}

.applications-container1 {
  text-align: center;
  font-size: 2rem;
  background-color: #e6e8f8;
  font-family: 'Poppins';
  font-weight: 600;
}

.application {
  display: flex;
  align-items: center;
  background-color: #e6e8f8;
  justify-content: center;
  max-width: 1600px;
  margin: 0 auto;
  font-family: 'Poppins';
}

.arrow-left,
.arrow-right {
  flex: none;
  width: 100px; /* Set a fixed width */
  text-align: center;
}

.application-info {
  flex: none;
  width: calc(100%); /* Subtract arrow widths from total */
  text-align: center;
  font-family: 'Poppins';
}

/* Rest of your existing styles */

.arrow-left:hover,
.arrow-right:hover {
  color: #4e32b3;
}

.arrow {
  height: 40px;
  width: auto;
}

.student-name {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: 'Poppins';
  color: #4e32b3;
}

.university-name {
  font-size: 1.2rem;
  color: #706f6f;
  margin-bottom: 10px;
  font-family: 'Poppins';
}

.degree-info,
.project-name {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

button {
  background-color: #6e44ff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Poppins';
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #4e32b3;
}

.dots {
  margin-top: 60px;
  display: flex;
  justify-content: center; /* Center the dots horizontally */
  align-items: center; /* Align dots vertically */
  width: 100%; /* Take up the full width */
}

.dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #c4c4c4;
  margin: 0 5px;
}

.dot.active {
  background-color: #6e44ff;
}

.dashboard-container3 {
  background-color: #e6e8f8; 
  font-family: 'Poppins';
  height: calc(100vh - 130px); /* Full viewport height */
  box-sizing: border-box;
  overflow: hidden;
  max-width: 1400px; /* Maximum width for the container */
  margin: 0 auto; /* Center the container horizontally */
  opacity: 0;
  transition: opacity 1s ease-in-out; /* Fade-in transition */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-container3.loaded {
  opacity: 1;
}

.upper-section3 {
  position: relative; /* Allows absolute positioning of children */
  padding: 0 20px;
  margin-top: 50px;
  height: calc(100vh - 200px);
}

/* Add styles for the green tick and collaborate section */
.collaboration-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.green-tick {
  width: 50px;
  height: 50px;
}

.collaborate-text {
  color: #6e44ff;
  font-size: 1.2rem;
  cursor: pointer;
  margin-top: 10px;
}

.collaborate-text:hover {
  color: #4e32b3;
}

.offer {
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: 'Poppins';
  color: #4e32b3;
  margin-top: 60px;
}

.offer:hover {
 color:black;
}

/* View Full Profile Link */
.view-profile {
  color: #6e44ff;
  cursor: pointer;
  font-size: 1.2rem;
  margin-top: 10px;
}

.view-profile:hover {
  text-decoration: underline;
}

/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup Content */
.popup-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  position: relative;
  font-family: 'Poppins';
  text-align: center;
  font-family: Poppins;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Popup Text */
.popup-content p {
  font-size: 1.2rem;
  color: #6d6d6d;
  margin: 10px 0;
  text-align: left;
}

/* Bold Labels */
.popup-content p strong {
  color: #000;
}

/* For screens smaller than 900px */
@media (max-width: 900px) {

  .applications-container1 {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    padding-top: 40px;
    margin-bottom: -50px;
  }

  .applications-content {
    padding-bottom: 150px;
    padding-top: 100px;
  }
  .application {
    flex-direction: column;
  }

  .arrow-left,
  .arrow-right,
  .application-info {
    flex: none;
    width: 100%;
  }

  .arrow-right {
    margin-top: 40px;
  }

  .arrow-left {
    visibility: hidden;
  }

}