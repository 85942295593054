/***********************************
  Overall Container
***********************************/
.cont {
  max-width: 1400px;
  margin: 0 auto;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  background-color: #fff;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; /* Left align everything */
  gap: 30px; 
}

.confirm-optimization-wrapper234 {
  width: 100%;
  background-color: #fff;
}

/* =========== TOP ROW =========== */
.top-row234 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.brief-section234 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.brief-title234 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  margin: 0 0 8px;
}

.powered234 {
  font-size: 1.1rem;
  color: #777;
  margin: 0;
  font-weight: 600;
}

.connect-section234,
.deliver-section234,
.connect-title234,
.deliver-title234,
.connect-step234,
.deliver-step234 {
  opacity: 0.5;
}

.powered234 span,
.connect-step234,
.deliver-step234 {
  color: #6e44ff; /* Purple text */
  font-weight: 600;
}

/* =========== MIDDLE FIELDS =========== */
.fields-container234 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/* Each field block: label + input area */
.field-block234 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.field-label234 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #222;
  margin: 0;
}

.field-input234 {
  width: 100%;
  background-color: #f6f7ff;
  border-radius: 20px;
  padding: 0;
  box-sizing: border-box;
}

.field-input234 textarea {
  width: 100%;
  border: none;
  border-radius: 20px;
  outline: none;
  background-color: #f6f7ff;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  color: #333;
  padding: 10px 15px;
  box-sizing: border-box;
  resize: none;
  line-height: 1.6;
}

/* =========== BOTTOM ROW =========== */
.bottom-row234 {
  width: 100%;
  display: flex;
  justify-content: flex-end; /* push both buttons to the right */
  align-items: center;
  margin-top: auto; 
  gap: 10px; /* 10px gap between the two buttons */
}

/* "Send to my Email" button */
.send-email-link234 {
  background-color: #fff;
  border: 2px solid #000;
  color: #000;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  padding: 10px 25px;
  border-radius: 40px;
  font-size: 1rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s;
}

/* Hover effect for the white button */
.send-email-link234:hover {
  background-color: #f0f0f0;
}

/* "Step 2 Connect to Talent" button */
.connect-talent-btn234 {
  background-color: #000;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  padding: 10px 25px;
  border-radius: 40px;
  font-size: 1rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s;
}

.connect-talent-btn234:hover {
  background-color: #444;
}

.connect-talent-btn234 span {
  font-size: 0.9rem;
  color: #fff;
  opacity: 0.8;
}

/* =========== POPUP OVERLAY STYLES (OPTIONAL) =========== */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-content {
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 12px;
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.popup-content h2 {
  font-size: 1.2rem;
  margin-bottom: 16px;
  color: #333;
}

.email-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.popup-button {
  border: none;
  border-radius: 8px;
  padding: 10px 16px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 0.95rem;
  transition: background-color 0.3s;
}

.popup-button.cancel {
  background-color: #ccc;
  color: #333;
}

.popup-button.cancel:hover {
  background-color: #bbb;
}

.popup-button:not(.cancel) {
  background-color: #6e44ff;
  color: #fff;
}

.popup-button:not(.cancel):hover {
  background-color: #5b36d9;
}

/* If you show an email-sent message */
.email-sent-message234 {
  margin-top: 10px;
  font-size: 0.95rem;
  color: green;
}

/* =========== RESPONSIVE =========== */
@media (max-width: 900px) {
  .top-row234 {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
}

@media (max-width: 600px) {
  .cont {
    padding: 20px;
  }

  .field-label234 {
    font-size: 1rem;
  }

  .field-input234 textarea {
    font-size: 0.95rem;
  }

  .connect-talent-btn234 {
    padding: 8px 18px;
  }
  .connect-talent-btn234 span {
    font-size: 0.8rem;
  }
}
