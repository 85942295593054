/* src/components/SetBudget.css */

/* 
 Wrap the entire layout in a container with max-width: 1400px, 
 full viewport height, and 25px border radius.
*/
.setbudget-wrapper {
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  min-height: 100vh;
  border-radius: 25px;
  overflow: hidden; /* ensures the rounded corners remain visible */
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column; /* Just ensures children stack if needed */
}

/* 
 The main page area inside the wrapper,
 which does the side-by-side layout for left col & right col.
*/
.setbudget-page {
  display: flex;
  flex: 1; /* fill the remaining vertical space in the wrapper */
}

/* ===== LEFT COLUMN (30%) ===== */
.sb-left-col {
  width: 30%;
  background-color: #fff;
  padding-left: 200px;
  padding-right: 50px;
  padding-top: 30px;
  box-sizing: border-box;
  display: flex; 
  flex-direction: column;
  overflow-y: auto; /* scroll if content is tall */
}

/* Top "Your Brief" header */
.sb-header-left {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 10px;
}

.sb-brief-title {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0;
  color: #333;
}

.sb-powered {
  font-size: 1.1rem;
  color: #777;
  margin: 0;
  font-weight: 600;
}
.sb-powered span {
  color: #6e44ff; /* Purple */
}

/* Fields */
.sb-field-block {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 30px;
}

.sb-field-label {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.sb-field-input {
  background-color: #f6f7ff;
  border-radius: 20px;
}

.sb-field-input textarea {
  width: 100%;
  border: none;
  outline: none;
  background-color: #f6f7ff;
  border-radius: 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.95rem;
  color: #333;
  padding: 10px 15px;
  resize: none;
  box-sizing: border-box;
  line-height: 1.5;
}

/* Note paragraph at the bottom */
.sb-note {
  font-size: 0.95rem;
  color: #666;
  line-height: 1.5;
  margin-top: auto; /* push it to the bottom of the column */
  margin-bottom: 60px;
}

/* ===== RIGHT COLUMN (70%) ===== */
.sb-right-col {
  width: 70%;
  background-color: #e6e8f8;
  padding-left: 50px;
  padding-right: 200px;
  padding-top: 50px;
  padding-bottom: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* scroll if content is too tall */
}

/* Steps row (/Connect to Talent, /Deliver Project) */
.sb-steps-row {
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  margin-bottom: 20px;
}

.sb-connect-step,
.sb-deliver-step {
  opacity: 0.7;
}

.sb-connect-title,
.sb-deliver-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0 4px 0;
  color: #333;
}

.sb-step-label {
  font-size: 0.95rem;
  color: #6e44ff;
  font-weight: 600;
  margin: 0;
}

.sb-secure-info {
  margin-top: 10%;
}

/* Secure funds info paragraph */
.sb-secure-info p {
  font-size: 1.3rem;
  color: #444;
  line-height: 1.6;
  margin-bottom: 16px;
}

/* Estimated cost, button, note */
.sb-estimated-cost {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: auto; /* push to bottom if you want that layout */
}

.sb-cost-label {
  font-size: 1.2rem;
  font-weight: 600;
  padding-left: 7px;
  padding-bottom: 5px;
  font-family: 'Poppins';
  color: #333;
  margin: 0;
}

.sb-cost-amount {
  font-size: 1.2rem;
  font-weight: 700;
  color: #333;
  background-color: #fff;
  color: #000;
  border: none;
  font-weight: 600;
  padding: 12px 28px;
  border-radius: 40px;
  margin-top: 5px;
  font-size: 1rem;
  cursor: pointer;
  font-family: 'Poppins';
  transition: background-color 0.3s;
  width: fit-content;
}

.sb-start-btn {
  background-color: #000;
  color: #fff;
  border: none;
  font-weight: 500;
  padding: 12px 28px;
  border-radius: 40px;
  font-family: 'Poppins';
  margin-top: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  width: fit-content;
}

.sb-start-btn:hover {
  background-color: #444;
}

.sb-projects-uploaded {
  font-size: 0.9rem;
  color: #777;
  margin-top: 4px;
}

/* ===== RESPONSIVE (under 900px) ===== */
@media (max-width: 900px) {

  /* Hide the left column entirely */
  .sb-left-col {
    display: none;
  }
  .sb-secure-info p {
    font-size: 1rem;
    color: #444;
    line-height: 1.6;
    margin-bottom: 30px;
  }

  /* Right column takes full width */
  .sb-right-col {
    width: 100%;
    border-radius: 0; /* optional on mobile */
  }

  /* Let the container height grow as needed */
  .setbudget-page {
    height: auto;
  }

  .sb-right-col {
    padding: 25px;
  }
}
