/* src/pages/PaymentPage.css */

.payment-container {
  max-width: 500px;
  margin: 40px auto;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
              0 5px 15px rgba(0, 0, 0, 0.07);
}

.payment-container h3 {
  margin-bottom: 20px;
  color: #32325d;
  text-align: center;
}

.payment-container h4 {
  margin-top: 30px;
  margin-bottom: 20px;
  color: #32325d;
  text-align: center;
}

.form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-row label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #6b7c93;
}

.payment-input,
#cardElement {
  padding: 12px;
  border: 1px solid #ccd0d5;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.payment-input::placeholder {
  color: #a0aec0;
}

.card-element-wrapper {
  border: 1px solid #ccd0d5;
  border-radius: 4px;
  padding: 12px;
}

#cardElement {
  background-color: #fff;
}

.pay-button {
  width: 100%;
  padding: 12px;
  background-color: #5469d4;
  color: #fff;
  border: none;
  border-radius: 15px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pay-button:hover {
  background-color: #3d58c7;
}

.pay-button:disabled {
  background-color: #bbb;
  cursor: not-allowed;
}

.card-error {
  color: #e55656;
  margin-top: 10px;
  text-align: center;
}

.secure-payment {
  margin-top: 20px;
  font-size: 14px;
  color: #6b7c93;
  text-align: center;
}

.powered-by-stripe {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: #6b7c93;
}

.powered-by-stripe img {
  margin-left: 5px;
  vertical-align: middle;
  height: 24px;
}

.payment-request-button {
  margin-bottom: 20px;
}

@media (max-width: 500px) {
  .payment-container {
    margin: 20px;
    padding: 15px;
  }

  .pay-button {
    padding: 10px;
  }
}
