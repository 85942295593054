/* Container for the entire section */
.container1234 {
  width: 100%;
  margin: 0 auto;
  padding: 0rem;
  font-family: 'Poppins', sans-serif;
  color: #2b2b2b;
  background-color: #e6e8f8; /* Will fade to #f8f9fd via GSAP */
  margin-bottom: 0;
  overflow: hidden;

  /* For parallax: let’s define a background position & size */
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-color 0.3s ease;
}

/* Main how-it-works section */
.how-it-works {
  max-width: 1450px;
  margin: 0 auto;
  padding: 2rem;
  background-color: inherit;
  margin-bottom: 400px;
}

/* Main heading */
.hiw-heading {
  display: inline-block; /* so letter spans will align horizontally */
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: left;
  position: relative;
}

/* Each letter */
.letter {
  display: inline-block;
  /* We'll animate from y: 40 to y: 0 in GSAP */
  transform: translateY(0);
  opacity: 1;
}

/* Spaces between words */
.letter-space {
  display: inline-block;
  width: 0.4rem; /* Tweak if you want slightly bigger or smaller space */
}

/* Subheading */
.hiw-subheading {
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 2rem;
  color: #333;
  text-align: left;
}

/* Steps Container */
.hiw-steps {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
}

/* Individual step card */
.hiw-step {
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 25px;
  flex: 1;
  min-width: 280px;
  margin: 0.5rem 0;
  padding: 1.5rem;
  cursor: pointer;
  text-align: left;

  /* We'll animate from rotateY(90deg) to rotateY(0deg) in GSAP */
  transform: rotateY(90deg);
  opacity: 0;

  transition: background-color 0.3s ease;
}

.hiw-step:hover {
  background-color: #d8daf5;
}

/* Step number */
.hiw-step-number {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #2c2c2c;
}

/* Step title */
.hiw-step-title {
  margin: 0.25rem 0 1rem 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #000;
}

/* Step description */
.hiw-step-description {
  margin: 0;
  font-size: 0.95rem;
  line-height: 1.5;
  color: #666;
}

/* Video container */
.hiw-video-container {
  width: 100%;
  background-color: #f8f9fd;
  border-radius: 20px;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1); /* We'll animate from scale(0.8) -> 1 */
  opacity: 1;          /* We'll animate from 0 -> 1 */
}

/* The video itself */
.hiw-video {
  width: 100%;
  max-width: 1400px;
  aspect-ratio: 16/9;
  border: none;
  border-radius: 8px;
  background-color: #e6e8f8;
}

@media (max-width: 768px) {
  .hiw-heading {
    font-size: 2rem;
  }

  .hiw-subheading {
    font-size: 1.2rem;
  }

  .hiw-video {
    width: 100%;
    padding: 0px;
  }
}
