/* src/BusinessDash.css */

/* Root container */
.business-dash-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh);
  margin-top: -80px;
  opacity: 0; 
  transition: opacity 0.8s ease-in-out;
  position: relative;
}
.business-dash-container.loaded {
  opacity: 1;
}
.business-dash-container.expanded-mode {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 130px);
  margin-top: 0px;
  transition: opacity 0.8s ease-in-out;
  position: relative;
}

/* ========== SIDEBAR ========== */
.sidebar {
  width: 25%;
  background-color: #fff;
  padding: 120px 20px; 
  box-sizing: border-box;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 80px; 
  transition: width 0.4s ease, padding 0.4s ease;
  overflow: hidden;
  position: relative;
}
.sidebar.collapsed {
  width: 0;
  padding: 0;
}

/* Each "section" => slash heading + content */
.section {
  display: flex;
  flex-direction: column;
  gap: 0; 
  margin: 0;
  padding: 0;
}

/* Slash heading default style */
.slash-heading {
  font-size: 1.4rem;
  font-weight: 900;
  color: #6e44ff;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}
.slash-heading.clicked {
  color: #000;
  transform: translateY(-4px);
}
.slash-heading:hover {
  color: #000;
}

/* "Use Moccet AI" button in sidebar */
.card-button31 {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 5px 30px;
  border-radius: 35px;
  font-size: 1.1rem;
  font-family: 'Poppins';
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 8px;
  width: 190px;
}
.card-button31:hover {
  background-color: #333;
}

/* ========== MAIN CONTENT ========== */
.main-content {
  width: 70%;
  background-color: #e6e8f8;
  padding: 120px 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 30px;
  transition: width 0.4s ease, padding 0.4s ease;
  position: relative; 
}
.main-content.fullwidth {
  width: 100%;
  padding: 40px 80px;
}

/* The dots pinned to the left edge of the main content */
.nav-dots {
  position: absolute;
  top: 180px;
  left: 0;         
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  cursor: pointer;
  transition: left 0.4s ease; 
}
.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.purple-dot {
  background-color: #6e44ff;
}
.grey-dot {
  background-color: #c4c4c4;
}

/* HEADERS & PROJECTS */
.welcome-title {
  font-size: 2.4rem;
  font-weight: 700;
  color: #3b3b3b;
}

.ongoing-projects-section {
  margin-top: 30px;
}

.ongoing-title,
.ongoing-title1 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.ongoing-title1 {
  margin-bottom: 0px;
}

/* Buttons in main content */
.card-button312 {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 5px 30px;
  border-radius: 35px;
  font-size: 1.1rem;
  font-family: 'Poppins';
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 0px;
  margin-bottom: 15px;
  width: 190px;
}
.card-button312:hover {
  background-color: #333;
}

/* Blog preview items in the sidebar */
.blog-preview-item {
  cursor: pointer;
  font-weight: 900;
  font-size: 1.3rem;
  margin-bottom: 5px;
}
.blog-preview-item:hover {
  font-weight: 500;
}
.blog-preview-item.second-item {
  opacity: 0.75;
}
.blog-preview-item.third-item {
  opacity: 0.55;
}

/* Chat item styling (matching BusinessChats) */
.chat-item {
  background: #fff;
  border: 1px solid #ccc;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border-radius: 20px;
  display: flex;
  cursor: pointer;
  position: relative;
}
.chat-item.unread {
  border: 2px solid #5e17eb;
}
.icon-bubble {
  position: relative;
  width: 50px;
  height: 50px;
  margin-right: 15px;
}
.chat-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.unread-badge {
  position: absolute;
  top: -4px;
  right: -4px;
  background-color: #5e17eb;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: bold;
}
.chat-text-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.chat-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.chat-user {
  font-size: 16px;
  font-weight: bold;
  color: black;
}
.chat-time {
  font-size: 14px;
  color: #555;
}
.chat-project {
  font-size: 14px;
  color: #000;
  margin: 5px 0;
}
.chat-last-message {
  font-size: 14px;
  color: #555;
  margin-top: 4px;
}

/* Fullwidth container for chat pages */
.chats-fullwidth {
  width: 100%;
}
.project-fullwidth {
  width: 100%;
  margin-top: -200px;
}

/* Responsive */
@media (max-width: 1150px) {
  .nav-dots {
    top: 150px;
  }
}

@media (max-width: 900px) {
  .business-dash-container {
    flex-direction: column;
    margin-top: 0px;
    height: calc(100vh - 160px);
    padding: 10px;
  }

  .main-content {
    width: 100%;
    padding: 0px 10px;
  }

  .main-content.fullwidth {
    width: 100%;
    padding: 0px 0px;
  }

  .sidebar {
    display: none;
  }
  .nav-dots {
    display: none;
  }
}
